<template>
  <div class="transportation-user-management">
    <v-layout row wrap>
      <v-flex md7 sm5 pa-2>
        <form @submit.prevent="searchUsers">
          <v-text-field
              solo
              v-model="searchKeywords"
              append-icon="fal fa-search"
              :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
              :disabled="apiInProgress"
              type="search"
              placeholder="Search by name, email address, phone number"
              @click:append="searchUsers"
          ></v-text-field>
        </form>
      </v-flex>
      <v-flex md3 sm4 pa-2>
        <v-select
          clearable
          :items="transportationRoles"
          label="Account Roles"
          :value="selectedRole"
          @change="onFilterByRole"
        />
      </v-flex>
      <v-flex md2 sm3 pa-2>
        <v-btn block color="primary" v-if="hasUserType('admin') || hasTransportationRole('ADMIN')" :disabled="apiInProgress" @click="$props.onCreateClicked && $props.onCreateClicked()"><v-icon>fal fa-plus</v-icon> &nbsp; Create User</v-btn>
      </v-flex>
    </v-layout>
    <v-tabs icons-and-text @change="onTabChanged">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab ripple href="#users">
        Users {{selectedUserTab !== 'users' ? `(${totalUsers})` : ''}}
        <v-icon>fal fa-user</v-icon>
      </v-tab>
      <v-tab ripple href="#invited-users" v-if="hasUserType('admin') || hasTransportationRole('ADMIN')">
        Invited Users {{selectedUserTab !== 'invited-users' ? `(${totalInvitedUsers})` : ''}}
        <v-icon>fal fa-user-plus</v-icon>
      </v-tab>
      <v-tab ripple href="#archived-users" v-if="hasUserType('admin') || hasTransportationRole('ADMIN')">
        Archived Users {{selectedUserTab !== 'archived-users' ? `(${totalInvitedUsers})` : ''}}
        <v-icon>fal fa-user-times</v-icon>
      </v-tab>
      <v-tabs-items v-model="selectedUserTab">
        <v-tab-item value="users">
          <v-data-table
              v-if="selectedUserTab === 'users'"
              :headers="headers"
              :items="users"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedUserTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr>
                <td class="layout justify-start">
                  <v-tooltip v-if="signedInUser && props.item.email === signedInUser.email" top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="margin: 0" flat small icon><v-icon small class="info--text" v-on="on">far fa-hand-point-right</v-icon></v-btn>
                    </template>
                    <span>Current user signed in</span>
                  </v-tooltip>
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.lastName"
                    hide-details
                  />
                </td>
                <td>
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.firstName"
                    hide-details
                  />
                </td>
                <td class="text-xs-left">
                  {{ props.item.phoneNumber }}
                  <img v-if="props.item.mobileMoneyAccountType" style="height: 30px;" :title="props.item.mobileMoneyAccountType" :src="moneyAccountLogos[props.item.mobileMoneyAccountType]" :alt="props.item.mobileMoneyAccountType" />
                  <div v-if="!props.item.mobileMoneyAccountType && props.item.moneyAccountType"><small>{{props.item.moneyAccountType.externalId}}</small></div>
                  <div v-if="!props.item.mobileMoneyAccountType && !props.item.moneyAccountType"><small class="amber--text">No money account</small></div>
                </td>
                <td class="text-xs-left" :title="props.item.stUser.email">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.email"
                    hide-details
                  />
                </td>
                <td class="text-xs-left"><small>{{ props.item.userTypeLabel }}</small></td>
                <td class="text-xs-left">{{ props.item.dateCreatedLabel }}</td>
                <td class="text-xs-left">{{ props.item.dateUpdatedLabel }}</td>
                <td class="justify-center layout px-0">
                  <v-btn flat icon :disabled="!props.item.isEditable" title="View/edit user" @click.stop="$props.onSelect && $props.onSelect(props.item.id)">
                    <v-icon small>
                      fas fa-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn flat icon :class="props.item.isOwner || !props.item.isEditable ? 'text--muted' : ''" :loading="props.item.isArchiveInProgress" :title="props.item.isOwner > -1 ? 'Account owner cannot be deleted, please archive the account' : 'Delete user'" @click.stop="deleteUser(props.item)">
                    <v-icon small>
                      fas fa-trash-alt
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="invited-users" v-if="hasUserType('admin') || hasTransportationRole('ADMIN')">
          <v-data-table
              v-if="selectedUserTab === 'invited-users'"
              :headers="invitationHeaders"
              :items="users"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedUserTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr :class="props.item.isExpired ? 'invited-user--expired' : ''">
                <td :title="props.item.stUser.lastName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.lastName"
                    hide-details
                  />
                </td>
                <td :title="props.item.stUser.firstName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.firstName"
                    hide-details
                  />
                </td>
                <td class="text-xs-left">{{ props.item.phoneNumber }}</td>
                <td class="text-xs-left" :title="props.item.stUser.email">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.email"
                    hide-details
                  />
                </td>
                <td class="text-xs-left">{{ props.item.userTypeLabel }}</td>
                <td class="text-xs-left">{{ props.item.dateCreatedLabel }}</td>
                <td class="text-xs-left">
                  <div>{{ props.item.verificationTokenExpiryLabel }}</div>
                  <div v-if="props.item.isExpired"><small class="error--text">(Expired)</small></div>
                </td>
                <td class="justify-center layout px-0">
                  <v-btn flat icon @click.stop="resendUserInvitation(props.item)" :disabled="!props.item.isEditable" title="Re-send invitation">
                    <v-icon small>
                      fas fa-envelope
                    </v-icon>
                  </v-btn>
                  <v-btn flat icon @click.stop="cancelInvitedUser(props.item)" :disabled="!props.item.isEditable" title="Cancel invitation">
                    <v-icon small>
                      fas fa-trash-alt
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="archived-users" v-if="hasUserType('admin') || hasTransportationRole('ADMIN')">
          <v-data-table
              v-if="selectedUserTab === 'archived-users'"
              :headers="archivedHeaders"
              :items="users"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedUserTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr>
                <td :title="props.item.stUser.lastName">
                  <v-text-field
                      class="text-field--flat"
                      v-model="props.item.stUser.lastName"
                      hide-details
                  />
                </td>
                <td :title="props.item.stUser.firstName">
                  <v-text-field
                      class="text-field--flat"
                      v-model="props.item.stUser.firstName"
                      hide-details
                  />
                </td>
                <td class="text-xs-left">{{ props.item.phoneNumber }}</td>
                <td class="text-xs-left" :title="props.item.stUser.email">
                  <v-text-field
                      class="text-field--flat"
                      v-model="props.item.stUser.email"
                      hide-details
                  />
                </td>
                <td class="text-xs-left">{{ props.item.userTypeLabel }}</td>
                <td class="text-xs-left">{{ props.item.dateCreatedLabel }}</td>
                <td class="text-xs-left">{{ props.item.dateArchivedLabel }}</td>
                <td class="justify-center layout px-0">
                  <v-btn flat icon @click.stop="unarchiveUser(props.item)" :disabled="!props.item.isEditable && !hasUserType('admin')" title="Un-archive user">
                    <v-icon small>
                      fas fa-undo
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-dialog v-model="modalSiteAlert" max-width="500px">
      <v-card>
        <v-card-title>
          {{modalSiteAlertData ? modalSiteAlertData.type : ''}}
        </v-card-title>
        <v-card-text style="overflow-y: scroll; max-height: 300px;" class="px-2 pb-2">
          {{modalSiteAlertData ? modalSiteAlertData.message : ''}}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" flat @click="modalSiteAlert = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
