<template>
  <div class="transportation-accounts-drivers">
    <div class="text-xs-center" v-if="$router.currentRoute.name === 'transportation-accounts-drivers'">
      <h1>Owners / Drivers</h1>
      <transportation-user-management
        :signed-in-user="signedInUser"
        :transportation-owner="selectedAccount"
        :transportation-owner-id="transportationOwnerId"
        :on-search="onUsersSearch"
        :on-create-clicked="onCreateClicked"
        :load-query-params="loadQueryParams"
        :on-select="onUserSelect"
      />
    </div>
    <router-view
      :force-root-view-refresh="forceRootViewRefresh"
      :signed-in-user="signedInUser"
      :has-user-type="hasUserType"
      :has-transportation-role="hasTransportationRole">
    </router-view>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
