import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import { isAuthorized } from './utilities/helpers'
import Home from './pages/home/template'
import SignOut from './pages/sign-out/template'
import { C_ADD_SITE_ALERT } from './utilities/mutation-types'
// import TransportationFares from './pages/transportation-fares/template'

Vue.use(Router)

const siteRouter = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/signout/',
      name: 'signout',
      component: SignOut,
      beforeEnter: (to, from, next) => {
        const token = store.getters.getToken
        if (token && token.expires > Date.now()) {
          if (!confirm('Confirm signing out')) {
            return next(false)
          }
        }
        next()
      }
    },
    {
      path: '/staff-resources/',
      name: 'staff-resources-group',
      redirect: { name: 'staff' }
    },
    {
      path: '/routes/',
      name: 'routes',
      meta: { requiresAuth: 'staff', title: 'Routes' },
      component: () => import(/* webpackChunkName: "routes" */ './pages/staff-resources/routes/template'),
      children: [
        {
          path: ':routeId/',
          name: 'routes-route',
          props: (route) => {
            const routeId = route.params.routeId

            if (!routeId) {
              return { routeId: '0' }
            }

            return { routeId }
          },
          meta: { title: 'Route' },
          component: () => import('./pages/staff-resources/routes/route/template'),
          children: [
            {
              path: 'fare-permutations/',
              name: 'routes-route-fare-permutations',
              meta: { title: 'Fare Permutations' },
              component: () => import('./pages/staff-resources/routes/route/fare-permutations/template')
            }
          ]
        }
      ]
    },
    {
      path: '/bus-stops/',
      name: 'bus-stops',
      meta: { requiresAuth: 'staff', title: 'Bus Stops' },
      component: () => import(/* webpackChunkName: "bus-stops" */ './pages/staff-resources/bus-stops/template')
    },
    {
      path: '/confirm/',
      name: 'confirm',
      meta: { title: 'Confirm Verification Link', hideNavigation: true },
      component: () => import(/* webpackChunkName: "confirm" */ './pages/confirm/template')
    },
    {
      path: '/staff/',
      name: 'staff',
      meta: { requiresAuth: 'staff', title: 'SmartTransit Staff' },
      component: () => import(/* webpackChunkName: "staff" */ './pages/staff-resources/staff/template')
    },
    {
      path: '/agents/',
      name: 'agents',
      meta: { requiresAuth: 'staff', title: 'Agents' },
      component: () => import(/* webpackChunkName: "agents" */ './pages/staff-resources/agents/template'),
      children: [
        {
          path: ':agentId/',
          name: 'agents-agent',
          props: (route) => {
            const agentId = route.params.agentId

            if (!agentId) {
              return { agentId: '0' }
            }

            return { agentId }
          },
          meta: { title: 'Agent Profile' },
          component: () => import('./pages/staff-resources/agents/agent/template')
        }
      ]
    },
    {
      path: '/transportation-fares/',
      name: 'transportation-fares',
      meta: { requiresAuth: 'staff', title: 'Transportation Fares' },
      component: () => import(/* webpackChunkName: "transportation-fares" */ './pages/staff-resources/transportation-fares/template')
    },
    {
      path: '/passengers/',
      name: 'passengers',
      meta: { requiresAuth: 'staff', title: 'Passengers' },
      component: () => import('./pages/staff-resources/passengers/template'),
      children: [
        {
          path: 'bulk-notifications/',
          name: 'passengers-bulk-notifications',
          meta: { requiresAuth: 'admin', title: 'Passenger Bulk Notifications' },
          component: () => import('./pages/staff-resources/passengers/bulk-notifications/template')
        },
        {
          path: ':passengerId/',
          name: 'passengers-passenger',
          props: (route) => {
            const passengerId = route.params.passengerId

            if (!passengerId) {
              return { passengerId: '0' }
            }

            return { passengerId }
          },
          meta: { title: 'Passenger Details' },
          component: () => import('./pages/staff-resources/passengers/passenger/template'),
          children: [
            {
              path: 'alternate-identifiers/',
              name: 'passengers-passenger-alternate-identifiers',
              meta: { title: 'Beacons/Tags' },
              component: () => import('./pages/staff-resources/passengers/passenger/alternate-identifiers/template')
            }
          ]
        }
      ]
    },
    {
      path: '/promotions/',
      name: 'promotions',
      meta: { requiresAuth: 'admin', title: 'Promotions' },
      component: () => import('./pages/staff-resources/promotions/template'),
      children: [
        {
          path: 'ad-hoc-promotion/',
          name: 'promotions-adhocpromotion',
          meta: { title: 'Send Promotional Credit' },
          component: () => import('./pages/staff-resources/promotions/ad-hoc-promotion/template')
        },
        {
          path: ':promotionId/',
          name: 'promotions-promotion',
          props: (route) => {
            const promotionId = route.params.promotionId

            if (!promotionId) {
              return { promotionId: '0' }
            }

            return { promotionId }
          },
          meta: { title: 'Promotion Setting' },
          component: () => import('./pages/staff-resources/promotions/promotion/template')
        }
      ]
    },
    {
      path: '/settings/hours-of-operation-templates/',
      name: 'hours-of-operation-templates',
      meta: { requiresAuth: 'admin', title: 'Hours Of Operations Templates' },
      component: () => import('./pages/staff-resources/hours-of-operation-templates/template')
    },
    {
      path: '/transportation-accounts-group/',
      name: 'transportation-accounts-group',
      redirect: { name: 'transportation-accounts' }
    },
    {
      path: '/transportation-accounts/',
      name: 'transportation-accounts',
      props: true,
      meta: { requiresAuth: ['staff', 'transporter'], requiresTransportAuth: ['OWNER', 'ADMIN'], title: 'Accounts' },
      component: () => import(/* webpackChunkName: "transportation-accounts" */ './pages/transportation-accounts/template'),
      children: [
        {
          path: ':accountId/',
          name: 'transportation-account',
          props: (route) => {
            let accountId = route.params.accountId

            if (accountId) {
              accountId = Number(accountId)

              if (isNaN(accountId)) {
                return { accountId: null }
              }
            }

            return { accountId }
          },
          meta: { title: 'Account' },
          component: () => import(/* webpackChunkName: "transportation-account" */ './pages/transportation-accounts/account/template'),
          children: [
            {
              path: 'payouts/',
              name: 'transportation-accounts-payouts',
              props: true,
              meta: { requiresAuth: ['admin', 'transporter'], requiresTransportAuth: ['OWNER', 'ADMIN'], title: 'Manage Payouts' },
              component: () => import(/* webpackChunkName: "transportation-accounts-payouts" */ './pages/transportation-accounts/payouts/template'),
              children: [
                {
                  path: 'schedule/',
                  name: 'transportation-accounts-payouts-schedule',
                  meta: { title: 'Scheduler' },
                  component: () => import(/* webpackChunkName: "transportation-accounts-payouts-payout-itinerary" */ './pages/transportation-accounts/payouts/schedule/template')
                },
                {
                  path: ':payoutId/salvage/',
                  name: 'transportation-accounts-payouts-salvage',
                  meta: { title: 'Salvage', requiresAuth: 'admin' },
                  component: () => import('./pages/transportation-accounts/payouts/salvage/template')
                },
                {
                  path: ':payoutId/',
                  name: 'transportation-accounts-payouts-payout',
                  props: (route) => {
                    let payoutId = route.params.payoutId
                    if (!payoutId) {
                      return { payoutId: '0' }
                    }
                    return { payoutId }
                  },
                  meta: { title: 'Payout' },
                  component: () => import(/* webpackChunkName: "transportation-accounts-payouts-payout-itinerary" */ './pages/transportation-accounts/payouts/payout/template')
                }
              ]
            },
            {
              path: 'revenue/',
              name: 'transportation-accounts-purchases',
              props: true,
              meta: { title: 'Revenue' },
              component: () => import(/* webpackChunkName: "transportation-accounts-revenue" */ './pages/transportation-accounts/revenue/template')
            },
            {
              path: 'vehicles/',
              name: 'transportation-accounts-vehicles',
              props: true,
              meta: { title: 'Vehicles' },
              component: () => import(/* webpackChunkName: "transportation-accounts-vehicles" */ './pages/transportation-accounts/vehicles/template'),
              children: [
                {
                  path: 'realtime/',
                  name: 'transportation-accounts-vehicles-realtime',
                  props: true,
                  meta: { title: 'Realtime Map' },
                  component: () => import('./pages/transportation-accounts/vehicles/realtime/template')
                },
                {
                  path: ':vehicleId/',
                  name: 'transportation-accounts-vehicle',
                  props: true,
                  meta: { title: 'Vehicle' },
                  component: () => import(/* webpackChunkName: "transportation-accounts-vehicle" */ './pages/transportation-accounts/vehicles/vehicle/template'),
                  children: [
                    {
                      path: 'itinerary/',
                      name: 'transportation-accounts-vehicle-itinerary',
                      props: true,
                      meta: { title: 'Vehicle Itinerary' },
                      component: () => import(/* webpackChunkName: "transportation-accounts-vehicle-itinerary" */ './pages/transportation-accounts/vehicles/vehicle/itinerary/template')
                    }
                  ]
                }
              ]
            },
            {
              path: 'drivers/',
              name: 'transportation-accounts-drivers',
              props: true,
              meta: { title: 'Owners/Drivers' },
              component: () => import(/* webpackChunkName: "transportation-accounts-drivers" */ './pages/transportation-accounts/drivers/template'),
              children: [
                {
                  path: ':transportationOwnerUserId/',
                  name: 'transportation-accounts-driver',
                  props: true,
                  meta: { title: 'Owner/Driver' },
                  component: () => import(/* webpackChunkName: "transportation-accounts-driver" */ './pages/transportation-accounts/drivers/driver/template')
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/vendor-resources/',
      name: 'vendor-resources-group',
      props: true,
      meta: { requiresAuth: 'vendor', title: 'Vendor Resources' },
      redirect: { name: 'vendor-passenger-transactions' },
      component: () => import(/* webpackChunkName: "vendor-resources-groups" */ './pages/vendor-resources/template'),
      children: [
        {
          path: 'transactions/',
          name: 'vendor-passenger-transactions',
          meta: { title: 'Passenger Transactions' },
          component: () => import(/* webpackChunkName: "vendor-passenger-transactions" */ './pages/vendor-resources/transactions/template')
        }
      ]
    },
    {
      path: '/directions/',
      name: 'directions',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "directions" */ './pages/directions/template')
    }
  ]
})

function _performAuthCheck (user, to, next) {
  let isPermitted
  let requiresAuth = to.meta.requiresAuth
  if (!requiresAuth && to.matched && to.matched.length) {
    for (let i = to.matched.length - 1; i >= 0; i--) {
      if (to.matched[i].meta.requiresAuth) {
        requiresAuth = to.matched[i].meta.requiresAuth
        break
      }
    }
  }
  if (user) {
    if (Array.isArray(requiresAuth)) {
      for (let i = 0; i < requiresAuth.length; i++) {
        isPermitted = isAuthorized(user, requiresAuth[i])
        if (isPermitted) {
          break
        }
      }
    } else {
      isPermitted = isAuthorized(user, requiresAuth)
    }
  } else {
    isPermitted = !requiresAuth || !requiresAuth.length
  }
  const parentRequiresTransportAuth = to.matched.some(m => m.meta.requiresTransportAuth)

  if (!isPermitted && (parentRequiresTransportAuth || to.meta.requiresTransportAuth)) {
    return !!_performTransportAuthCheck(user, to, next)
  } else if (!isPermitted) {
    store.commit(C_ADD_SITE_ALERT, {
      message: `Not authorized to view the page: ${to.meta.title}.${user ? '' : ' You may need to <a href="/?forward=' + to.path + '" class="warn warning">sign in</a> again.'}`,
      type: 'error'
    })
    next(false)
    return false
  } else {
    next()
    return true
  }
}

function _performTransportAuthCheck (user, to, next) {
  let isPermitted
  let requiresTransportAuth = to.meta.requiresTransportAuth
  if (!requiresTransportAuth && to.matched && to.matched.length) {
    for (let i = to.matched.length - 1; i >= 0; i--) {
      if (to.matched[i].meta.requiresTransportAuth) {
        requiresTransportAuth = to.matched[i].meta.requiresTransportAuth
        break
      }
    }
  }
  if (user?.transportationOwnerUsers) {
    const transportationOwnerUser = user.transportationOwnerUsers.find((o) => (to.query.accountId === o.transportationOwnerId))

    if (transportationOwnerUser) {
      const transportationOwnerUserRoles = transportationOwnerUser.roles.split(',')

      isPermitted = transportationOwnerUserRoles.find((o) => {
        if (Array.isArray(requiresTransportAuth)) {
          return requiresTransportAuth.find((o2) => (o2 === o))
        } else {
          return o === requiresTransportAuth
        }
      })
    }
  } else {
    isPermitted = !requiresTransportAuth || !requiresTransportAuth.length
  }

  if (!isPermitted) {
    store.commit(C_ADD_SITE_ALERT, {
      message: `Not authorized to view the page: ${to.meta.title}.${user ? '' : ' You may need to <a href="/?forward=' + to.path + '" class="warn warning">sign in</a> again.'}`,
      type: 'error'
    })
    next(false)
    return false
  } else {
    next()
    return true
  }
}

let userPromiseHandle
siteRouter.beforeEach((to, from, next) => {
  const parentRequiresAuth = to.matched.some(m => m.meta.requiresAuth)
  const parentRequiresTransportAuth = to.matched.some(m => m.meta.requiresTransportAuth)
  // console.log('parentRequiresAuth, parentRequiresTransportAuth', parentRequiresAuth, parentRequiresTransportAuth, to.matched)
  if (to.meta.requiresAuth || parentRequiresAuth || to.meta.requiresTransportAuth || parentRequiresTransportAuth) {
    const user = store.state?.credentials?.user
    const token = store.getters.getToken
    if (!token || token.expires <= Date.now()) {
      console.log('no token')
      if (!_performAuthCheck(null, to, next)) {
        if (to.name === 'home') {
          siteRouter.push({ name: 'home' })
        } else {
          siteRouter.push({ name: 'home', query: { forward: to.fullPath } })
        }
      }
    } else {
      if (user) {
        console.log('checking sequentially')
        _performAuthCheck(user, to, next)
      } else {
        console.log('checking async')
        const userPromise = store.state.credentials.userPromise
        if (userPromise) {
          userPromise.finally(() => {
            console.log('promise complete')
            const user = store.state?.credentials?.user
            _performAuthCheck(user, to, next)
          })
        } else {
          if (userPromiseHandle) {
            clearTimeout(userPromiseHandle)
            userPromiseHandle = null
          }
          console.log('no async, setting timeout')
          userPromiseHandle = setTimeout(() => {
            const userPromise = store.state.credentials.userPromise
            if (userPromise) {
              userPromise.finally(() => {
                const user = store.state?.credentials?.user
                _performAuthCheck(user, to, next)
              })
            } else {
              const user = store.state?.credentials?.user
              if (user) {
                _performAuthCheck(user, to, next)
              } else {
                next(false)
              }
            }
          }, 2000)
        }
      }
    }
  } else {
    next()
  }
})

export default siteRouter
