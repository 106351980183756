import { initBreadcrumbs } from '@/utilities/helpers'
import { addParamsToLocation } from '@smarttransit/common-client'

export default {
  name: 'transportation-accounts-drivers',
  props: {
    signedInUser: Object,
    hasUserType: Function,
    hasTransportationRole: Function,
    forceRootViewRefresh: Function,
    selectedAccount: Object
  },
  data () {
    return {
      loadQueryParams: null
    }
  },
  watch: {
    $route () {
      this.breadcrumbs = initBreadcrumbs.call(this)

      if (this.$router.currentRoute.name === 'transportation-accounts-drivers') {
        this.loadFromQueryParams()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.breadcrumbs = initBreadcrumbs.call(this)
      this.loadFromQueryParams()
    })
  },
  computed: {
    transportationOwnerId () {
      return this.$props.selectedAccount ? this.$props.selectedAccount.id : (this.$route.params.accountId === '0' ? null : parseInt(this.$route.params.accountId))
    }
  },
  methods: {
    loadFromQueryParams () {
      this.loadQueryParams = {}
      const pagination = {}

      if (this.$route.query.descending) {
        pagination.descending = this.$route.query.descending
      }

      if (this.$route.query.page) {
        pagination.page = this.$route.query.page
      }

      if (this.$route.query.rowsPerPage) {
        pagination.rowsPerPage = this.$route.query.rowsPerPage
      }

      if (this.$route.query.sortBy) {
        pagination.sortBy = this.$route.query.sortBy
      }

      if (Object.keys(pagination).length) {
        this.loadQueryParams = pagination
      }

      if (this.$route.query.tab) {
        this.loadQueryParams.tab = this.$route.query.tab
      }
    },
    onUsersSearch (params) {
      addParamsToLocation(params, this.$route, this.lastParams)
    },
    onCreateClicked () {
      this.$router.push({ name: 'transportation-accounts-driver', params: { transportationOwnerUserId: '0' } })
    },
    onUserSelect (transportationOwnerUserId) {
      this.$router.push({ name: 'transportation-accounts-driver', params: { transportationOwnerUserId: String(transportationOwnerUserId) } })
    }
  }
}
