import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getTransportationOwnerUser ({ id, filter }) {
  return apiRequests.get('/v1/transportation-owner-user/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTransportationOwnerUsers (filter) {
  return apiRequests.get('/v1/transportation-owner-user/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalTransportationOwnerUsers ({ where, join }) {
  return apiRequests.get('/v1/transportation-owner-user/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteTransportationOwnerUser ({ id }) {
  return apiRequests.delete('/v1/transportation-owner-user/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createTransportationOwnerUser ({ transportationOwnerId, firstName, lastName, phone, email, roles, verificationType, details, noVerificationNotice }) {
  return apiRequests.post('/v1/transportation-owner/' + transportationOwnerId + '/transportation-owner-user/', { firstName, lastName, phone, email, roles, verificationType, details, noVerificationNotice }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function resendTransportationOwnerUserInvite ({ transportationOwnerUserId, transportationOwnerId }) {
  return apiRequests.put('/v1/transportation-owner/' + transportationOwnerId + '/transportation-owner-user/' + transportationOwnerUserId + '/', {}, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateTransportationOwnerUser ({ id, firstName, lastName, phone, email, role, details, excludeFeeLabel }) {
  return apiRequests.patch('/v1/transportation-owner-user/' + id + '/', { firstName, lastName, phone, email, role, details, excludeFeeLabel }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function verifyTransportationOwnerUserInvite ({ verificationToken, password, confirmedPassword }) {
  return apiRequests.put('/v1/transportation-owner-user/invitation/verification/', { verificationToken, password, confirmedPassword }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteTransportationOwnerUserInvite ({ transportationOwnerUserId }) {
  return apiRequests.delete('/v1/transportation-owner-user/' + transportationOwnerUserId + '/invitation/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function unarchiveTransportationOwnerUser ({ transportationOwnerUserId }) {
  return apiRequests.patch('/v1/transportation-owner-user/' + transportationOwnerUserId + '/unarchived/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createScheduledPayout ({ transportationOwnerUserId, scheduledDate, frequency, frequencyDate, notifyOnCompletion, notifyOnCompletionContacts, transportationUserMomoAccountId, transportationUserMoneyAccountId, agencyId, lastDayOfMonth, timezone }) {
  return apiRequests.post('/v1/transportation-owner-user/' + transportationOwnerUserId + '/scheduled-payout/', { scheduledDate, frequency, frequencyDate, notifyOnCompletion, notifyOnCompletionContacts, transportationUserMomoAccountId, transportationUserMoneyAccountId, agencyId, lastDayOfMonth, timezone }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateScheduledPayout ({ transportationOwnerUserId, frequency, frequencyDate, notifyOnCompletion, notifyOnCompletionContacts, transportationUserMomoAccountId, transportationUserMoneyAccountId, agencyId, lastDayOfMonth, archived, timezone }) {
  return apiRequests.patch('/v1/transportation-owner-user/' + transportationOwnerUserId + '/scheduled-payout/', { frequency, frequencyDate, notifyOnCompletion, notifyOnCompletionContacts, transportationUserMomoAccountId, transportationUserMoneyAccountId, agencyId, lastDayOfMonth, archived, timezone }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getScheduledPayout ({ transportationOwnerUserId }) {
  return apiRequests.get('/v1/transportation-owner-user/' + transportationOwnerUserId + '/scheduled-payout/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteScheduledPayout ({ transportationOwnerUserId }) {
  return apiRequests.delete('/v1/transportation-owner-user/' + transportationOwnerUserId + '/scheduled-payout/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createTransportationUserMoneyAccount ({ externalId, accountMetadata, moneyAccountTypeId, transportationOwnerUserId }) {
  return apiRequests.post('/v1/transportation-owner-user/' + transportationOwnerUserId + '/transportation-user-money-account/',
    { externalId, accountMetadata, moneyAccountTypeId },
    { headers: { 'Authorization': store.getters.getTokenId } }
  ).then((result) => {
    return result.data
  })
}

function updateTransportationUserMoneyAccount ({ id, externalId, accountMetadata, moneyAccountTypeId, transportationOwnerUserId }) {
  return apiRequests.patch('/v1/transportation-user-money-account/' + id + '/',
    { externalId, accountMetadata, moneyAccountTypeId },
    { headers: { 'Authorization': store.getters.getTokenId } }
  ).then((result) => {
    return result.data
  })
}

async function getTransportationOwnerMomoAndMoneyAccounts ({ transportationOwnerId, transportationOwnerUserIds }) {
  // get the payout account types for this owner
  const transportationOwnerUsers = await findTransportationOwnerUsers({
    where: { transportationOwnerId, ...(transportationOwnerUserIds ? { id: { inq: transportationOwnerUserIds } } : {}), roles: { like: '%OWNER%' }, verified: true },
    include: ['transportationUserMoneyAccounts', { transportationUserIdentifiers: 'transportationUserMomoAccounts' }]
  })

  // populate the list of momo accounts and any other money accounts
  let transportationMoneyAccounts = []
  let transportationMomoAccounts = []

  if (transportationOwnerUsers?.length) {
    transportationMomoAccounts = transportationOwnerUsers.reduce((acc, currentVal) => {
      const { transportationUserIdentifiers, transportationUserMoneyAccounts, ...transportationOwnerUser } = currentVal

      acc = acc.concat(currentVal.transportationUserIdentifiers.reduce((subAcc, subCurrentVal) => {
        const { transportationUserMomoAccounts, ...transportationUserIdentifier } = subCurrentVal

        subAcc = subAcc.concat(subCurrentVal.transportationUserMomoAccounts.map((o) => {
          return {
            transportationOwnerUser,
            transportationUserIdentifier,
            ...o
          }
        }))

        return subAcc
      }, []))

      return acc
    }, [])

    transportationMoneyAccounts = transportationOwnerUsers.reduce((acc, currentVal) => {
      const { transportationUserMoneyAccounts, ...transportationOwnerUser } = currentVal

      acc = acc.concat(currentVal.transportationUserMoneyAccounts.map((o) => {
        return {
          transportationOwnerUser,
          ...o
        }
      }))
      return acc
    }, [])
  }

  return { transportationMomoAccounts, transportationMoneyAccounts }
}

export {
  getTransportationOwnerMomoAndMoneyAccounts,
  createTransportationUserMoneyAccount,
  updateTransportationUserMoneyAccount,
  unarchiveTransportationOwnerUser,
  getTransportationOwnerUser,
  findTransportationOwnerUsers,
  findTotalTransportationOwnerUsers,
  deleteTransportationOwnerUser,
  createTransportationOwnerUser,
  updateTransportationOwnerUser,
  resendTransportationOwnerUserInvite,
  verifyTransportationOwnerUserInvite,
  deleteTransportationOwnerUserInvite,
  createScheduledPayout,
  updateScheduledPayout,
  getScheduledPayout,
  deleteScheduledPayout
}
