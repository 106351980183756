import _upperFirst from 'lodash.upperfirst'
import { formatDate, formatTime } from '@smarttransit/common'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'

import {
  hasTransportationRole as hasTransportationRoleFunc,
  isAuthorized,
  addAlert,
  ROOT_EMAIL
} from '../../utilities/helpers'

import {
  findTotalTransportationOwnerUsers,
  findTransportationOwnerUsers,
  deleteTransportationOwnerUser,
  deleteTransportationOwnerUserInvite,
  resendTransportationOwnerUserInvite,
  unarchiveTransportationOwnerUser
} from '../../services/transportation-owner-users-service'

export default {
  name: 'transportation-user-management',
  props: {
    signedInUser: Object,
    transportationOwnerId: Number,
    transportationOwner: Object,
    loadQueryParams: Object,
    onSelect: Function,
    onCreateClicked: Function,
    onDelete: Function,
    onSearch: Function,
    onUserCreated: Function,
    onUserDeleted: Function,
    onUserUpdated: Function,
    onError: Function
  },
  data () {
    return {
      users: [],
      totalUsers: 0,
      totalArchivedUsers: 0,
      totalInvitedUsers: 0,
      userModalApiInProgress: false,
      apiInProgress: false,
      isArchiveInProgress: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateUpdated'
      },
      searchKeywords: '',
      selectedUserTab: 'users',
      modalSiteAlertData: null,
      modalSiteAlert: false,
      selectedRole: null,
      phonePrefix: null,
      moneyAccountLogos: {},
      moneyAccountTypes: [],
      transportationRoles: [],
      userTypesByAuthorization: [],
      headers: [],
      invitationHeaders: [],
      archivedHeaders: []
    }
  },
  watch: {
    transportationOwner (val) {
      const transportationOwner = val
      if (transportationOwner) {
        this.setPhonePrefixByCountryId(transportationOwner.currentCountryId)
      }
    },
    loadQueryParams (val) {
      if (val && Object.values(val).length) {
        if (val.pagination) {
          this.pagination = { ...val.pagination }
        }

        this.searchKeywords = val.searchKeywords
        this.selectedUserTab = val.tab
        this.selectedRole = val.role
        this.searchUsers(val.tab)
      }
    }
  },
  mounted: function () {
    this.moneyAccountLogos = this.$store.getters.getMoneyAccountLogos
    this.transportationRoles = this.$store.getters.getTransportationRoles

    let headers = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'User Type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Date Updated',
        align: 'left',
        sortable: true,
        value: 'dateUpdated'
      },
      { text: 'Actions', sortable: false }
    ]

    this.headers = headers

    this.invitationHeaders = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'User Type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Invitation Expiry Date',
        align: 'left',
        sortable: true,
        value: 'verificationTokenExpiry'
      },
      { text: 'Actions', sortable: false }
    ]

    this.archivedHeaders = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'User Type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Date Archived',
        align: 'left',
        sortable: true,
        value: 'dateArchived'
      },
      { text: 'Actions', sortable: false }
    ]

    if (this.$props.signedInUser) {
      let filteredUserTypes = [{ text: 'Not authorized to set a user type', value: 'none' }]
      if (this.$props.signedInUser) {
        if (this.hasUserType('admin') || this.hasTransportationRole('OWNER')) {
          filteredUserTypes = this.transportationRoles.filter(o => o.value !== 'OWNER')
        } else if (this.hasTransportationRole('ADMIN')) {
          filteredUserTypes = this.transportationRoles.filter(o => o.value === 'DRIVER')
        }
      }
      this.userTypesByAuthorization = filteredUserTypes
    } else {
      this.userTypesByAuthorization = []
    }

    if (this.$props.transportationOwner) {
      this.setPhonePrefixByCountryId(this.$props.transportationOwner.currentCountryId)
    }

    this.searchUsers()
  },
  methods: {
    hasUserType (role) {
      const { signedInUser } = this.$props
      return (signedInUser && isAuthorized(signedInUser, role))
    },
    setPhonePrefixByCountryId (countryId) {
      switch (countryId) {
        case 'GHA':
          this.phonePrefix = '+233'
          break
      }
    },
    hasTransportationRole (transportationRole) {
      const { signedInUser } = this.$props
      if (signedInUser) {
        this.transportationOwnerUser = this.transportationOwnerUser || signedInUser.transportationOwnerUsers.find((o) => (o.transportationOwnerId === this.$props.transportationOwnerId))
        return hasTransportationRoleFunc(signedInUser, transportationRole, this.$props.transportationOwnerId, this.transportationOwnerUser)
      }
    },
    addAlert (message, type, isModal = undefined) {
      if (this.type === 'error' && typeof this.$props.onError === 'function') {
        this.$props.onError(new Error(message))
      }
      if (isModal) {
        this.modalSiteAlertData = { type: type.toUpperCase(), message }
        this.modalSiteAlert = true
      } else {
        addAlert({
          message,
          type,
          transient: type === 'success'
        })
      }
    },
    currentUserHasRole (role) {
      const user = this.$store?.state?.credentials?.user || []
      return isAuthorized(user, role)
    },
    resendUserInvitation (user) {
      if (confirm(`Confirm re-sending invitation for ${user.stUser.firstName || ''} ${user.stUser.lastName}`)) {
        const { transportationOwnerId } = this.$props

        resendTransportationOwnerUserInvite({ transportationOwnerUserId: user.id, transportationOwnerId })
          .then((result) => {
            this.searchUsers('invited-users')
            this.addAlert(`Successfully re-sent invitation for ${user.stUser.firstName || ''} ${user.stUser.lastName}`, 'success')
          }).catch((err) => {
            this.addAlert(`Error in re-sending invite for user: ${JSON.stringify(err)}`, 'error', 'isModal')
          })
      }
    },
    async cancelInvitedUser (user) {
      if (confirm(`Confirm cancelling invitation for ${user.stUser.firstName || ''} ${user.stUser.lastName}`)) {
        await deleteTransportationOwnerUserInvite({ transportationOwnerUserId: user.id })
        this.searchUsers('invited-users')
        this.addAlert(`Successfully deleted invitation for ${user.stUser.firstName || ''} ${user.stUser.lastName}`, 'success')
      }
    },
    async unarchiveUser (user) {
      if (confirm(`Confirm un-archiving user ${user.stUser.firstName || ''} ${user.stUser.lastName}`)) {
        await unarchiveTransportationOwnerUser({ transportationOwnerUserId: user.id })
        this.searchUsers()
        this.addAlert(`Successfully un-archived user ${user.stUser.firstName || ''} ${user.stUser.lastName}`, 'success')
      }
    },
    onTabChanged (val) {
      if (this.pagination) {
        this.searchUsers(val)
      }
    },
    onPagination () {
      this.searchUsers(this.selectedUserTab)
    },
    onFilterByRole (val) {
      this.selectedRole = val
      this.searchUsers(this.selectedUserTab)
    },
    deleteUser (user) {
      if (user.isOwner || !user.isEditable) {
        return alert('Account owner cannot be deleted, please archive the account')
      }

      if (this.$props.onDelete) {
        return this.$props.onDelete(user)
      }

      if (this.hasUserType('admin') || this.hasTransportationRole('ADMIN')) {
        if (confirm(`Confirm permanently deleting "${user.stUser.firstName || ''} ${user.stUser.lastName}" from this account?`)) {
          user.isArchiveInProgress = true

          deleteTransportationOwnerUser({
            id: user.id
          }).then(() => {
            this.searchUsers(this.selectedUserTab)
            this.addAlert(`Successfully removed user ${user.stUser.firstName || ''} ${user.stUser.lastName} from account`, 'success')
          }).catch((err) => {
            this.addAlert(`Error in removing user from account: ${err && err.error.message ? err.error.message : JSON.stringify(err)}`, 'error')
          }).finally(() => {
            user.isArchiveInProgress = false
          })
        }
      }
    },
    addArchivedWhere (filter) {
      filter.where.dateArchived = { neq: null }
    },
    getTotal (category) {
      if (category === 'invited-users') {
        return this.totalInvitedUsers
      } else if (category === 'archived-users') {
        return this.totalArchivedUsers
      } else {
        return this.totalUsers
      }
    },
    getUserTypeLabel (roles, returnValue) {
      if (roles.indexOf('OWNER') > -1) {
        return returnValue ? 'OWNER' : 'Owner'
      }
      if (roles.indexOf('ADMIN') > -1) {
        return returnValue ? 'ADMIN' : 'Admin'
      }
      if (roles.indexOf('DRIVER') > -1) {
        return returnValue ? 'DRIVER' : 'Driver / Assistant'
      }
    },
    isUserEditable (user, ignoreIfEmailSame) {
      let isEditable = false
      if ((!ignoreIfEmailSame && this.$props.signedInUser.email === user.email) || this.$props.signedInUser.email === ROOT_EMAIL) {
        isEditable = true
      } else if (user.roles.indexOf('OWNER') > -1 || user.roles.indexOf('ADMIN') > -1) {
        isEditable = this.hasUserType('admin') || this.hasTransportationRole('OWNER')
      } else {
        isEditable = this.hasTransportationRole('ADMIN')
      }
      return isEditable
    },
    /**
     *
     * @param {string} category - can be 'invited' or 'archived'
     * @returns {Promise<void>}
     */
    async searchUsers (category = undefined) {
      let promises = []
      this.apiInProgress = true
      let retrievedUsers
      this.$props.onSearch && this.$props.onSearch({ pagination: this.pagination, tab: category, searchKeywords: this.searchKeywords })

      if (category === 'invited-users') {
        this.getTotalUsers().then((total) => {
          this.totalUsers = total.count
        })

        this.getTotalArchivedUsers().then((total) => {
          this.totalArchivedUsers = total.count
        })

        promises.push(this.getInvitedUsers())
        promises.push(this.getTotalInvitedUsers())
        const [ users, total ] = await Promise.all(promises)
        this.totalInvitedUsers = total.count
        retrievedUsers = users
      } else if (category === 'archived-users') {
        this.getTotalUsers().then((total) => {
          this.totalUsers = total.count
        })

        this.getTotalInvitedUsers().then((total) => {
          this.totalInvitedUsers = total.count
        })

        promises.push(this.getArchivedUsers())
        promises.push(this.getTotalArchivedUsers())
        const [ users, total ] = await Promise.all(promises)
        this.totalInvitedUsers = total.count
        retrievedUsers = users
      } else {
        if (this.hasUserType('admin') || this.hasTransportationRole('ADMIN')) {
          this.getTotalInvitedUsers().then((total) => {
            this.totalInvitedUsers = total.count
          })

          this.getTotalArchivedUsers().then((total) => {
            this.totalArchivedUsers = total.count
          })
        }

        promises.push(this.getUsers())
        promises.push(this.getTotalUsers())
        const [ users, total ] = await Promise.all(promises)
        this.totalUsers = total.count
        retrievedUsers = users
      }

      this.users = retrievedUsers.map(o => {
        o.email = o.stUser.email
        o.isExpired = new Date(o.verificationTokenExpiry).getTime() <= Date.now()
        o.verificationTokenExpiryLabel = category === 'invited-users' ? `${formatTime(o.verificationTokenExpiry)}, ${formatDate(o.verificationTokenExpiry)}` : null
        o.dateCreatedLabel = `${formatTime(o.dateCreated)}, ${formatDate(o.dateCreated)}`
        o.dateUpdatedLabel = o.dateUpdated ? `${formatTime(o.dateUpdated)}, ${formatDate(o.dateUpdated)}` : ''
        o.dateArchivedLabel = o.dateArchived ? `${formatTime(o.dateArchived)}, ${formatDate(o.dateArchived)}` : ''
        o.userTypeLabel = this.getUserTypeLabel(o.roles)
        o.invitedUserTypesLabel = o.metadata?.invitedRoles
        o.invitedUserTypesLabel = o.invitedUserTypesLabel && o.invitedUserTypesLabel.length ? o.invitedUserTypesLabel.map(o => _upperFirst(o)).join(', ') : ''
        const hasMobileMoney = o.transportationUserIdentifiers?.length && o.transportationUserIdentifiers.find((o) => o.transportationUserMomoAccounts?.length)
        o.phoneNumber = hasMobileMoney ? o.transportationUserIdentifiers[0].phoneNumber : o.stUser.phone

        if (hasMobileMoney) {
          o.mobileMoneyAccountType = o.transportationUserIdentifiers && o.transportationUserIdentifiers.length ? o.transportationUserIdentifiers[0].transportationUserMomoAccounts : null
          o.mobileMoneyAccountType = o.mobileMoneyAccountType && o.mobileMoneyAccountType.length ? o.mobileMoneyAccountType[0].moneyAccountTypeId : null
        } else if (o.transportationUserMoneyAccounts && o.transportationUserMoneyAccounts.length) {
          o.moneyAccountType = o.transportationUserMoneyAccounts[0]
        }

        o.isEditable = this.isUserEditable(o)
        o.isOwner = o.roles.indexOf('OWNER') > -1

        return o
      })

      this.apiInProgress = false
    },
    getGenericFilter ({ archived = false, verified = true } = {}) {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage

      let filter = {
        limit: rowsPerPage,
        offset,
        where: { verified },
        include: ['stUser', 'transportationUserMoneyAccounts', { transportationUserIdentifiers: 'transportationUserMomoAccounts' }]
      }

      if (archived) {
        this.addArchivedWhere(filter)
      } else {
        filter.where.dateArchived = null // { eq: null }
      }

      if (sortBy) {
        filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
      }

      if (this.$props.transportationOwnerId) {
        filter.where.transportationOwnerId = this.$props.transportationOwnerId
      } else {
        filter.include.push('transportationOwner')
      }

      if (this.selectedRole) {
        if (this.selectedRole === 'DRIVER') {
          filter.where.roles = { ilike: this.selectedRole }
        } else if (this.selectedRole === 'ADMIN') {
          filter.where.roles = [{ ilike: `%${this.selectedRole}%` }, { nilike: `%OWNER%` }]
        } else if (this.selectedRole === 'OWNER') {
          filter.where.roles = { ilike: `%${this.selectedRole}%` }
        }
      }

      if (this.searchKeywords) {
        filter.join = [{
          relation: 'stUser',
          scope: { where: { or: generateKeywordsQueryForOr(this.searchKeywords, ['firstName', 'lastName', 'email', 'phone']) } }
        },
        {
          relation: 'transportationUserIdentifiers',
          scope: { where: { or: generateKeywordsQueryForOr(this.searchKeywords, ['phoneNumber']), verified } }
        }]
      }

      return filter
    },
    async getUsers () {
      let filter = this.getGenericFilter()
      return findTransportationOwnerUsers(filter)
    },
    async getTotalUsers () {
      let filter = this.getGenericFilter()
      return findTotalTransportationOwnerUsers(filter)
    },
    async getInvitedUsers () {
      let filter = this.getGenericFilter({ verified: false })
      return findTransportationOwnerUsers(filter)
    },
    async getTotalInvitedUsers () {
      let filter = this.getGenericFilter({ verified: false })
      return findTotalTransportationOwnerUsers(filter)
    },
    async getArchivedUsers () {
      let filter = this.getGenericFilter({ archived: true, verified: false })
      return findTransportationOwnerUsers(filter)
    },
    async getTotalArchivedUsers () {
      let filter = this.getGenericFilter({ archived: true, verified: false })
      return findTotalTransportationOwnerUsers(filter)
    }
  },
  beforeDestroy () {
    if (this.selectedSignedInUserWatchHandle) {
      this.selectedSignedInUserWatchHandle()
    }
  }
}
