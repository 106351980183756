<template>
  <div>
    <div v-if="$router.currentRoute.name === 'promotions-promotion'" style="position: relative" class="promotions-promotion">
      <h1 class="text-xs-center">Promotion Setting</h1>
      <br />
      <div class="px-2" style="position: absolute; top: 25px; left: 0; width: 100%;"><v-progress-linear indeterminate :active="apiInProgress" height="4px" /></div>
      <v-form v-if="currentPromotion && !currentPromotionError" @submit.prevent="save()" ref="form" class="mx-2">
        <div class="layout justify-end">
          <router-link :to="{ name: 'promotions' }"><v-btn color="warning mr-2" flat :disabled="apiInProgress">Cancel</v-btn></router-link>
           <v-btn color="primary" type="submit" :disabled="apiInProgress || !isDataEdited()" :loading="apiInProgress">Save</v-btn>
        </div>
        <div class="layout row wrap justify-space-between align-start">
          <div style="width: 60%">
            <v-layout v-if="currentPromotion.id" row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" mt-3 sm4 pr-3>
                <span v-show="!currentPromotion.metadata"><v-icon small>fa fa-dolly-empty</v-icon> Draft</span>
                <span v-show="currentPromotion.metadata"><v-icon small>fa fa-dolly</v-icon> &nbsp; Published</span>
              </v-flex>
              <v-flex sm8>
                <v-btn v-show="!currentPromotion.metadata" :loading="publishInProgress" :disabled="!!(apiInProgress || publishInProgress || currentPromotion.dateArchived)" @click="publishPromotion()"><v-icon small>fa fa-dolly</v-icon> &nbsp; Publish</v-btn>
                <v-btn color="warning" v-show="currentPromotion.metadata" :loading="publishInProgress" :disabled="!!(apiInProgress || publishInProgress || currentPromotion.dateArchived)" @click="unpublishPromotion()"><v-icon small>fa fa-dolly-empty</v-icon> &nbsp; Un-Publish</v-btn>
                <span v-show="currentPromotion.dateArchived">(Archived)</span>
              </v-flex>
            </v-layout>
            <v-layout v-if="currentPromotion.id" row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
                Budget spent
              </v-flex>
              <v-flex sm8>
                <em>{{currentPromotion.budgetSpentLabel}}</em>
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                Custom name
              </v-flex>
              <v-flex sm8>
                <v-text-field :rules="[v => v && v.trim() ? true : 'Please enter a value']" required placeholder="Enter name" v-model="currentPromotion.customName" />
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                Script name
              </v-flex>
              <v-flex sm8 class="layout row justify-start align-baseline">
                <v-select
                  class="mr-2"
                  :rules="[v => v && v.trim() ? true : 'Please select a value']"
                  dense
                  outline
                  :items="promotionScripts"
                  :disabled="scriptsInProgress"
                  :loading="scriptsInProgress"
                  placeholder="Select promotion script"
                  v-model="currentPromotion.fileName"
                  @change="setCurrentScript"
                />
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">fa fa-info-circle</v-icon>
                  </template>
                  <span v-text="computedScriptDescription"></span>
                </v-tooltip>
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">fa fa-info-circle</v-icon>
                  </template>
                  <span>Restricts the promotion to the country the account is created in or<br />
                    the vehicle a ticket is purchased from</span>
                </v-tooltip>
                Country
              </v-flex>
              <v-flex sm8 class="layout row justify-start align-baseline">
                <v-select
                  class="mr-2"
                  :rules="[v => v && v.trim() ? true : 'Please select a value']"
                  dense
                  outline
                  :items="[{ text: 'Ghana', value: 'GHA' }]"
                  :loading="scriptsInProgress"
                  placeholder="Select country"
                  v-model="currentPromotion.draftMetadata.countryId"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">fa fa-info-circle</v-icon>
                  </template>
                  <span>Budget currency is based on the selected country (edited only by superadmins)</span>
                </v-tooltip>
                 Budget
              </v-flex>
              <v-flex sm8>
                <currency-field v-model="currentPromotion.budget" :child-props="{ disabled: !currentPromotion.id || !isSuperAdmin, placeholder: currentPromotion.id ? 'Enter amount' : 'n/a', persistentHint: true, hint: currentPromotion.id ? '' : 'Budget can be set by super admin after creating this promotion' }" />
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">fa fa-info-circle</v-icon>
                  </template>
                  <span>Budget threshold currency is based on the selected country (edited only by superadmins)</span>
                </v-tooltip>
                 Budget threshold
              </v-flex>
              <v-flex sm8>
                <currency-field v-model="currentPromotion.budgetThreshold" :child-props="{ disabled: !currentPromotion.id || !isSuperAdmin, placeholder: currentPromotion.id ? 'Enter amount less than budget' : 'n/a', persistentHint: true, hint: currentPromotion.id ? '' : 'Budget threshold can be set by super admin after creating this promotion' }" />
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">fa fa-info-circle</v-icon>
                  </template>
                  <span>When the threshold amount is reached and crossed, any contacts added<br />
                    in the "warning" list will be notified</span>
                </v-tooltip>
                Budget threshold contacts
              </v-flex>
              <v-flex sm8>
                <v-btn mb-2 small @click="addPhoneContactModal = true"><v-icon small>fa fa-plus</v-icon> &nbsp; Add phone contact</v-btn>
                <v-combobox
                    v-model="currentPromotion.draftMetadata.warnContactsIfOverBudget"
                    label="Contacts can be emails or phone numbers"
                    multiple
                    dense
                    persistent-hint
                    small-chips
                    :rules="contactsRules"
                >
                  <template v-slot:no-data>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          Type in an email or use the button to add a phone number
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                </v-combobox>
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                Start / End date timezone
              </v-flex>
              <v-flex sm8>
                <div class="layout row justify-start align-start">
                  <div v-if="currentPromotion.draftMetadata.timezone !== localTimezone">
                    <v-btn flat icon @click="resetTimezone()"><v-icon small>fal fa-undo</v-icon></v-btn>
                  </div>
                  <div style="flex: 1">
                    <timezone-selector :child-props="{ outline: true, label: 'Enter keywords to filter' }" v-model="currentPromotion.draftMetadata.timezone" />
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                Starts
              </v-flex>
              <v-flex sm8>
                <div class="layout justify-start align-start">
                  <div class="mr-3">
                    <v-menu
                      v-model="dateStartMenu"
                      :close-on-content-click="false"
                      full-width
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          prepend-icon="fal fa-calendar"
                          :value="computedDateStartFormatted"
                          @click:clear="currentPromotion.dateStartTimezoned = null"
                          clearable
                          placeholder="Select start date"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        no-title
                        :max="computedDateStartMax"
                        :width="373"
                        v-model="currentPromotion.dateStartTimezoned"
                        @change="updateCurrentPromotionObj(() => dateStartMenu = false)"
                      />
                    </v-menu>
                  </div>
                  <div>
                    <time-field v-model="currentPromotion.dateStartTime" :other-props="{ label: 'Time', placeholder: '12:00am' }" />
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                Ends
              </v-flex>
              <v-flex sm8>
                <div class="layout justify-start align-start">
                  <div class="mr-3">
                    <v-menu
                        v-model="dateEndMenu"
                        :close-on-content-click="false"
                        full-width
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            prepend-icon="fal fa-calendar"
                            :value="computedDateEndFormatted"
                            @click:clear="currentPromotion.dateEndTimezoned = null"
                            clearable
                            placeholder="Select end date"
                            readonly
                            v-on="on"
                        />
                      </template>
                      <v-date-picker
                          no-title
                          :min="computedDateEndMin"
                          :width="373"
                          v-model="currentPromotion.dateEndTimezoned"
                          @change="updateCurrentPromotionObj(() => dateEndMenu = false)"
                      />
                    </v-menu>
                  </div>
                  <div>
                    <time-field v-model="currentPromotion.dateEndTime" :other-props="{ label: 'Time', placeholder: '11:59pm' }" />
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="currentScript" style="width: 38%">
            <v-card class="pa-2 text-xs-center">
              <h3>{{currentScript.text}}</h3>
              <h3>Custom Settings</h3>
              <br />
              <div v-show="!currentScript.metadata.custom">No custom data to set</div>
              <template v-for="(item, prop) in currentScript.metadata.custom">
                <div class="mb-2 layout row justify-start align-baseline" :key="'metadata-row-' + prop">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">fa fa-info-circle</v-icon>
                    </template>
                    <span>{{item.description}}</span>
                  </v-tooltip>
                  <div class="ml-2" style="flex: 1">
                    <div v-if="!item.options && !item.multiOptions">
                      <v-text-field v-if="!['currency', 'date', 'dayTimeRanges'].includes(item.type)"
                        :rules="item.required ? [v => v && v.trim() ? true : 'Please enter a value'] : []"
                        v-model="currentScript.metadata.custom[prop].value"
                        :type="item.type"
                        :label="convertCustomPropToLabel(prop)" />
                      <v-menu
                        v-if="item.type === 'date'"
                        v-model="dateMenuItems[prop]"
                        :close-on-content-click="false"
                        offset-y
                        full-width
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            prepend-icon="fal fa-calendar"
                            :value="formatCustomPropDate(currentScript.metadata.custom[prop].value)"
                            @click:clear="currentScript.metadata.custom[prop].value = null"
                            clearable
                            :label="convertCustomPropToLabel(prop)"
                            readonly
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          no-title
                          v-model="currentScript.metadata.custom[prop].value"
                          @change="dateMenuItems[prop] = false"
                        />
                      </v-menu>
                      <currency-field v-if="item.type === 'currency'"
                        :child-props="{ rules: item.required ? [v => v && v.trim() ? true : 'Please enter a value'] : [], label: convertCustomPropToLabel(prop) }"
                        v-model="currentScript.metadata.custom[prop].value" />
                      <div v-if="item.type === 'dayTimeRanges'">
                        <div class="text-xs-right">
                          <v-btn small @click="loadDayTimeRangeModal({ days: [], times: [] })">Add new day/time entry</v-btn>
                        </div>
                        <v-data-table
                          :headers="[{ text: 'Day(s)', value: 'day', sortable: false }, { text: 'Time Range(s)', value: 'timeRange', sortable: false }]"
                          :items="currentScript.metadata.custom[prop].value || []"
                          class="elevation-0"
                          hide-actions
                        >
                          <template v-slot:items="props">
                            <tr class="pointer" @click="loadDayTimeRangeModal(props.item, props.index)">
                              <td>
                                <template v-for="(day, index) in props.item.days">
                                  <v-chip :key="index" small>{{formatCustomPropToWeekday(day)}}</v-chip>
                                </template>
                                <span v-if="!props.item.days || !props.item.days.length">n/a</span>
                              </td>
                              <td>
                                <template v-for="(timeRange, index) in props.item.times">
                                  <v-chip :key="index" small>{{formatCustomPropToTimeRange(timeRange.start, timeRange.end)}}</v-chip>
                                </template>
                                <span v-if="!props.item.times || !props.item.times.length">n/a</span>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </div>
                    </div>
                     <v-select
                      v-if="item.options"
                      outline
                      dense
                      :items="item.options.map(o => ({ text: o.description, value: o.value }))"
                      :label="convertCustomPropToLabel(prop)"
                      v-model="currentScript.metadata.custom[prop].selectedOption"
                    />
                    <v-select
                      v-if="item.multiOptions"
                      outline
                      multiple
                      dense
                      small-chips
                      :items="item.multiOptions.map(o => ({ text: o.description, value: o.value }))"
                      :label="convertCustomPropToLabel(prop)"
                      v-model="currentScript.metadata.custom[prop].value"
                    />
                    <div v-if="item.options && isCustomOptionRequired(item.options, currentScript.metadata.custom[prop].selectedOption)">
                      <v-text-field
                        v-if="getCustomOptionType(item.options, currentScript.metadata.custom[prop].selectedOption) !== 'currency'"
                        :rules="[v => v && v.trim() ? true : 'Please enter a value']"
                        v-model="currentScript.metadata.custom[prop].value"
                        :type="getCustomOptionType(item.options, currentScript.metadata.custom[prop].selectedOption)"
                        :label="getCustomOptionValueLabel(item.options, currentScript.metadata.custom[prop].selectedOption)" />
                      <currency-field
                        v-if="getCustomOptionType(item.options, currentScript.metadata.custom[prop].selectedOption) === 'currency'"
                        :child-props="{ rules: [v => v && v.trim() ? true : 'Please enter a value'], label: getCustomOptionValueLabel(item.options, currentScript.metadata.custom[prop].selectedOption) }"
                        v-model="currentScript.metadata.custom[prop].value" />
                    </div>
                  </div>
                </div>
              </template>
            </v-card>
          </div>
        </div>
        <div class="layout justify-end">
          <v-btn v-if="currentPromotion.id" :disabled="!!(apiInProgress || currentPromotion.dateArchived)" :loading="apiInProgress" @click="archivePromotion"><v-icon small>fa fa-trash-alt</v-icon> &nbsp; <span v-show="currentPromotion.dateArchived">Archived</span><span v-show="!currentPromotion.dateArchived">Archive</span></v-btn>
          <router-link :to="{ name: 'promotions' }"><v-btn color="warning" class="ml-2" flat :disabled="apiInProgress">Cancel</v-btn></router-link>
           <v-btn color="primary" type="submit" class="ml-2" :disabled="apiInProgress || !isDataEdited()" :loading="apiInProgress">Save</v-btn>
        </div>
      </v-form>
      <v-dialog v-model="addPhoneContactModal" width="500px">
        <v-card>
          <v-card-title class="lighten-2 grey justify-center dialog-close-container">
            <h2>Budget Threshold: Add a phone contact</h2>
            <v-btn class="dialog-close" color="primary" flat icon @click="addPhoneContactModal = false"><v-icon>far fa-times</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="pa-2 layout column align-center justify-center">
            <phone-number-field v-model="phoneContactToAdd" :child-props="{ placeholder: 'Enter number' }" />
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" :disabled="!phoneContactToAdd || phoneContactToAdd.indexOf('+') === -1" @click="addPhoneToContacts">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dayTimeRangeModal" width="500px">
        <v-card>
          <v-card-title class="justify-center dialog-close-container">
            <h2>Add/Update Day/Time</h2>
            <v-btn class="dialog-close" color="primary" flat icon @click="dayTimeRangeModal = false">
              <v-icon>far fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="px-2 pb-2 pt-0">
            <div class="layout justify-space-between mb-2">
              <div>
                <v-select
                  v-model="currentDaySet"
                  :items="[{ text: 'Monday', value: 1 }, { text: 'Tuesday', value: 2 }, { text: 'Wednesday', value: 3 }, { text: 'Thursday', value: 4 }, { text: 'Friday', value: 5 }, { text: 'Saturday', value: 6 }, { text: 'Sunday', value: 0 }]"
                  dense
                  clearable
                  placeholder="Select day"
                />
              </div>
              <div><v-btn small @click="addDayEntry()">Add</v-btn></div>
            </div>
            <div class="layout justify-space-between mb-2">
              <div><time-field v-model="currentTimeSet.start" :other-props="{ label: 'From', placeholder: 'e.g. 5:00am' }" /></div>
              <div><time-field v-model="currentTimeSet.end" :other-props="{ label: 'To', placeholder: 'e.g. 11:00pm' }" /></div>
              <div><v-btn small @click="addTimeEntry()">Add</v-btn></div>
            </div>
            <table v-if="currentDayTimeRange" style="border: none; width: 100%">
              <tr style="vertical-align: top">
                <td style="width: 50%">
                  <template v-for="(day, index) in currentDayTimeRange.days">
                    <v-chip :key="index" close small @input="onDayTimeItemDelete('days', index)">{{formatCustomPropToWeekday(day)}}</v-chip>
                  </template>
                  <span v-if="!currentDayTimeRange.days || !currentDayTimeRange.days.length">n/a</span>
                </td>
                <td style="width: 50%">
                  <template v-for="(timeRange, index) in currentDayTimeRange.times">
                    <v-chip :key="index" close small @input="onDayTimeItemDelete('times', index)">{{formatCustomPropToTimeRange(timeRange.start, timeRange.end)}}</v-chip>
                  </template>
                  <span v-if="!currentDayTimeRange.times || !currentDayTimeRange.times.length">n/a</span>
                </td>
              </tr>
            </table>
            <div class="layout row justify-center">
              <v-btn color="error" flat @click="deleteDayTimeEntry()">
                <v-icon small>fas fa-trash</v-icon> &nbsp;
                Remove All
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" @click="addUpdateDayTimeRange()">Done</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-alert :value="currentPromotionError" type="error">
        <p>{{currentPromotionError}}</p>
        <p><a href @click.stop="refreshView()">Retry</a> or <router-link :to="{ name: 'promotions' }">Return to promotions list</router-link></p>
      </v-alert>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
