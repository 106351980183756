<template>
  <div class="transportation-accounts-purchases">
    <form @submit.prevent="searchTransactions">
      <div class="justify-space-between align-center layout">
        <div>
          <v-btn v-if="isSuperAdmin" @click="openAdHocRevenueDialog" :disabled="apiInProgress">
            <v-icon left>fal fa-badge-dollar</v-icon> Submit Ad Hoc Revenue
          </v-btn> &nbsp;
          <v-btn v-if="isAdmin" @click="goToPayout" :disabled="apiInProgress">
            <v-icon left>fal fa-envelope-open-dollar</v-icon> Payout current trip results
          </v-btn>
        </div>
      </div>
      <v-layout row wrap>
        <v-flex sm4 pa-2>
          <v-select
              :disabled="apiInProgress"
              clearable
              :items="[{ text: 'Already paid out', value: 'onlypaid' }, { text: 'Pending payout', value: 'pendingpaid' }]"
              label="Payout filter"
              v-model="selectedPaidFilter"
          />
        </v-flex>
        <v-flex sm3 pa-2>
          <v-autocomplete
            v-model="selectedVehicle"
            :items="vehicles"
            :loading="vehiclesApiInProgress"
            :search-input.sync="vehicleKeywords"
            hide-no-data
            hide-selected
            item-text="licensePlateNumber"
            item-value="id"
            label="Vehicle"
            placeholder="Search by licence plate"
            prepend-icon="fal fa-bus"
            @change="onFilterByVehicle"
            clearable
            return-object
          />
        </v-flex>
        <v-flex sm4>
          <v-layout>
            <v-flex xs6 pa-2>
              <v-menu
                v-model="dateFromMenu"
                :close-on-content-click="false"
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="fal fa-calendar"
                    :value="computedDateFromFormatted"
                    @click:clear="dateFrom = null"
                    clearable
                    label="From"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  no-title
                  :max="computedDateFromMax"
                  v-model="dateFrom"
                  @change="dateFromMenu = false"
                />
              </v-menu>
            </v-flex>
            <v-flex xs6 pa-2>
              <v-menu
                v-model="dateToMenu"
                :close-on-content-click="false"
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="fal fa-calendar"
                    :value="computedDateToFormatted"
                    @click:clear="dateTo = null"
                    clearable
                    label="To"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  no-title
                  :min="computedDateToMin"
                  v-model="dateTo"
                  @change="dateToMenu = false"
                />
              </v-menu>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex sm1 pa-2>
          <v-btn small fab :disabled="apiInProgress" @click="searchTransactions"><v-icon small>fal fa-filter</v-icon></v-btn>
        </v-flex>
      </v-layout>
    </form>
    <v-data-table
      :headers="headers"
      :items="transactions"
      :pagination.sync="pagination"
      :total-items="totalTransactions"
      :loading="apiInProgress"
      :rows-per-page-items="[10, 25, 50]"
      @update:pagination="onPagination"
      class="elevation-1"
      must-sort
    >
      <template v-slot:items="props">
        <tr>
          <td>
            {{props.item.amountLabel}}
          </td>
          <td class="text-xs-left">
            {{props.item.dateCreatedLabel}}
          </td>
          <td class="text-xs-left">
            <small>{{props.item.logType}}</small>
          </td>
          <td class="text-xs-left">
            <v-chip v-if="props.item.transportationUserTransactionLogId" color="success" text-color="white" small>
              <v-icon small left>fa fa-check-circle</v-icon>
              <small>{{props.item.payoutLabel}}</small>
            </v-chip>
            <span v-if="!props.item.transportationUserTransactionLogId">{{props.item.payoutLabel}}</span>
          </td>
          <td class="text-xs-left">{{props.item.transportationProfileName}}</td>
          <td class="text-xs-left d-flex justify-start wrap">
            <span v-if="props.item.notesLabel" class="mr-2" v-html="`<small>${props.item.notesLabel}</small>`" />
            <span v-if="isSuperAdmin && props.item.logType === 'ad_hoc_revenue' && props.item.id && !props.item.transportationUserTransactionLogId">
              <v-btn flat icon @click="updateAdHocRevenueDialog(props.item)"><v-icon small>fa fa-pencil</v-icon></v-btn>
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        persistent
        max-width="500"
        v-model="adhocRevenueDialog">
      <v-card v-if="adhocRevenue">
        <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
          Submit Ad Hoc Revenue
        </v-card-title>
        <div class="pa-2">
          <currency-field
            pb-2
            v-model="adhocRevenue.amount"
            @blur="(val) => adhocRevenue.amount = val"
            :child-props="{ label: 'Amount', disabled: addingAdhocRevenue }"
          />
          <div class="mb-2" style="width: 300px">
            <v-menu
              v-model="adhocDate"
              :close-on-content-click="false"
              full-width
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  prepend-icon="fal fa-calendar"
                  :value="computedAdHocDateFormatted"
                  @click:clear="adhocRevenue.dateCreated = null"
                  clearable
                  label="Transaction Date"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                no-title
                v-model="adhocRevenue.dateCreated"
                @change="adhocDate = false"
              />
            </v-menu>
          </div>
          <v-autocomplete
              v-model="adhocRevenue.transportationProfileId"
              :items="vehicles"
              :loading="vehiclesApiInProgress"
              :search-input.sync="adHocVehicleKeywords"
              hide-no-data
              hide-selected
              item-text="licensePlateNumber"
              item-value="id"
              label="Vehicle"
              placeholder="Search by licence plate"
              prepend-icon="fal fa-bus"
              outline
              clearable
          />
          <v-text-field
            pb-2
            :disabled="addingAdhocRevenue"
            v-model="adhocRevenue.notes"
            label="Notes"
          />
          <div class="mb-2">
            <v-text-field
              :disabled="addingAdhocRevenue"
              v-model="adhocRevenue.confirmationId"
              label="Confirmation ID"
              persistent-hint
              hint="(Optional) Can be bank deposit slip number, mobile money transaction ID, etc."
            />
          </div>
        </div>
        <v-divider />
        <v-card-actions>
          <v-btn color="warning" :disabled="addingAdhocRevenue" flat @click="adhocRevenueDialog = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn color="primary" :disabled="addingAdhocRevenue || !adhocRevenue.amount || !adhocRevenue.dateCreated" :loading="addingAdhocRevenue" @click="saveAdhocRevenue()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
