import { initBreadcrumbs } from '../../../utilities/helpers'

export default {
  name: 'agents',
  props: {
    signedInUser: Object
  },
  data () {
    return {
      breadcrumbs: []
    }
  },
  watch: {
    $route () {
      this.breadcrumbs = initBreadcrumbs.call(this)

      if (this.$router.currentRoute.name === 'agents') {
        // this.loadFromQueryParams()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.breadcrumbs = initBreadcrumbs.call(this)
    })
  },
  methods: {
    onCreateAgentClicked () {
      this.$router.push({ name: 'agents-agent', params: { agentId: '0' } })
    },
    onAgentSelected (agent) {
      this.$router.push({ name: 'agents-agent', params: { agentId: agent.id } })
    }
  }
}
