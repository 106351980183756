import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'

import {
  hasTransportationRole as hasTransportationRoleFunc,
  isAuthorized,
  addAlert,
  ROOT_EMAIL
} from '../../../../utilities/helpers'

import {
  deleteTransportationOwnerUser,
  deleteTransportationOwnerUserInvite,
  resendTransportationOwnerUserInvite,
  createTransportationOwnerUser,
  updateTransportationOwnerUser,
  getTransportationOwnerUser,
  archiveTransportationUserMoneyAccount,
  createTransportationUserMoneyAccount, updateTransportationUserMoneyAccount
} from '../../../../services/transportation-owner-users-service'

import {
  updateTransportationUserIdentifier,
  createTransportationUserIdentifier,
  deRegisterTransportationUserMomoAccount
} from '../../../../services/transportation-user-identifiers-service'

import { formatDate, formatTime } from '@smarttransit/common/src/helpers'
import { getCachedMoneyAccountTypes } from '@/services/st-settings-service'

export default {
  name: 'transportation-user',
  props: {
    signedInUser: Object,
    transportationOwnerUserId: String
  },
  data () {
    return {
      apiInProgress: false,
      selectedUser: null,
      phonePrefix: null,
      moneyAccountTypes: [],
      unfilteredMoneyAccountTypes: [],
      transportationRoles: [],
      userTypesByAuthorization: [],
      moneyAccountModal: false,
      selectedMoneyAccount: null,
      isMoneyAccountLoading: false
    }
  },
  computed: {
    selectedMoneyAccountCategory () {
      return this.getMoneyAccountCategory(this.selectedMoneyAccount?.moneyAccountTypeId)
    }
  },
  mounted: function () {
    this.moneyAccountTypes = this.$store.getters.getMoneyAccountTypes
    this.moneyAccountLogos = this.$store.getters.getMoneyAccountLogos
    this.transportationRoles = this.$store.getters.getTransportationRoles

    getCachedMoneyAccountTypes().then((result) => {
      this.unfilteredMoneyAccountTypes = result || []
    })

    if (this.$props.signedInUser) {
      let filteredUserTypes = [{ text: 'Not authorized to set a user type', value: 'none' }]

      if (this.$props.signedInUser) {
        if (this.hasUserType('admin') || this.hasTransportationRole('OWNER')) {
          filteredUserTypes = this.transportationRoles.filter(o => o.value !== 'OWNER')
        } else if (this.hasTransportationRole('ADMIN')) {
          filteredUserTypes = this.transportationRoles.filter(o => o.value === 'DRIVER')
        }
      }

      this.userTypesByAuthorization = filteredUserTypes
    } else {
      this.userTypesByAuthorization = []
    }

    this.$nextTick(() => {
      if (this.$route.params.transportationOwnerUserId === '0') {
        this.selectedUser = {
          userType: this.userTypesByAuthorization && this.userTypesByAuthorization.length === 1 ? this.userTypesByAuthorization[0].value : 'DRIVER',
          verificationType: 'phone'
        }

        this.clonedSelectedUser = _cloneDeep(this.selectedUser)
      } else {
        this.loadUser(this.$route.params.transportationOwnerUserId)
      }
    })
  },
  methods: {
    getMoneyAccountCategory (moneyAccountTypeId) {
      return this.unfilteredMoneyAccountTypes.find(o => o.typeId === moneyAccountTypeId)?.typeCategory
    },
    loadMoneyAccountModal (data) {
      // this will accommodate both mobile money and money account
      this.selectedMoneyAccount = {
        externalId: '',
        accountMetadata: {},
        transportationUserIdentifier: {},
        ...data
      }

      this.moneyAccountModal = true
    },
    async deregisterMomoAccount (id, phoneNumber) {
      if (confirm(`Confirm removing this mobile money account: ${phoneNumber}`)) {
        try {
          const result = await deRegisterTransportationUserMomoAccount({ id })

          if (result?.success) {
            addAlert({ message: 'Successfully removed mobile money account', type: 'success' })
          }

          this.selectedUser.transportationUserIdentifiers.forEach((transportationUserIdentifier, index) => {
            this.selectedUser.transportationUserIdentifiers[index].transportationUserMomoAccounts = transportationUserIdentifier.transportationUserMomoAccounts.filter(o => o.id !== id)
          })

          this.selectedUser.transportationMobileMoneyAccounts = this.getTransportationMobileMoneyAccounts(this.selectedUser.transportationUserIdentifiers)
          this.clonedSelectedUser.transportationMobileMoneyAccounts = _cloneDeep(this.selectedUser.transportationMobileMoneyAccounts)
          this.clonedSelectedUser.transportationUserIdentifiers = _cloneDeep(this.selectedUser.transportationUserIdentifiers)
          return result?.success
        } catch (err) {
          addAlert({ message: err, type: 'error' })
        }
      }
    },
    async archiveMoneyAccount (id, externalId) {
      if (confirm(`Confirm removing this account: ${externalId}`)) {
        try {
          const result = await archiveTransportationUserMoneyAccount(id)

          if (result?.success) {
            addAlert({ message: 'Successfully removed money account', type: 'success' })
          }

          this.selectedUser.transportationUserMoneyAccounts = this.selectedUser.transportationUserMoneyAccounts.filter(o => o.id !== id)
          this.clonedSelectedUser.transportationUserMoneyAccounts = _cloneDeep(this.selectedUser.transportationUserMoneyAccounts)
          return result?.success
        } catch (err) {
          addAlert({ message: err, type: 'error' })
        }
      }
    },
    async archiveSelectedMoneyAccount () {
      let result = false

      if (this.selectedMoneyAccountCategory === 'bank') {
        result = await this.archiveMoneyAccount(this.selectedMoneyAccount.id, this.selectedMoneyAccount.externalId)
      } else {
        result = await this.deregisterMomoAccount(this.selectedMoneyAccount.id, this.selectedMoneyAccount.transportationUserIdentifier.phoneNumber)
      }

      if (result) {
        this.moneyAccountModal = false
      }
    },
    async saveMoneyAccount () {
      if (this.selectedMoneyAccountCategory === 'mobile_money') {
        return this.saveMobileMoneyUser()
      } else {
        try {
          let result = null

          if (this.selectedMoneyAccountCategory === 'bank') {
            if (!this.selectedMoneyAccount.externalId ||
                !this.selectedMoneyAccount.accountMetadata.bankName ||
                !this.selectedMoneyAccount.accountMetadata.accountFullname) {
              return alert('Please enter account number, bank name and account holder\'s full name')
            }
          }

          if (this.selectedMoneyAccount.id) {
            this.isMoneyAccountLoading = true

            result = await updateTransportationUserMoneyAccount({
              id: this.selectedMoneyAccount.id,
              transportationOwnerUserId: this.selectedUser.id,
              moneyAccountTypeId: this.selectedMoneyAccount.moneyAccountTypeId,
              externalId: this.selectedMoneyAccount.externalId,
              accountMetadata: this.selectedMoneyAccount.accountMetadata
            })
          } else {
            result = await createTransportationUserMoneyAccount({
              transportationOwnerUserId: this.selectedUser.id,
              moneyAccountTypeId: this.selectedMoneyAccount.moneyAccountTypeId,
              externalId: this.selectedMoneyAccount.externalId,
              accountMetadata: this.selectedMoneyAccount.accountMetadata
            })
          }

          const transportationUserMoneyAccountIndex = (this.selectedUser.transportationUserMoneyAccounts || []).findIndex((o) => o.id === result.id)

          if (transportationUserMoneyAccountIndex > -1) {
            this.selectedUser.transportationUserMoneyAccounts[transportationUserMoneyAccountIndex] = result
          } else {
            this.selectedUser.transportationUserMoneyAccounts.push(result)
          }

          this.clonedSelectedUser.transportationUserMoneyAccounts = _cloneDeep(this.selectedUser.transportationUserMoneyAccounts)
          this.moneyAccountModal = false
          addAlert({ message: `Successfully updated money account ${this.selectedMoneyAccount.externalId}`, type: 'success' })
        } catch (err) {
          addAlert({ message: err, type: 'error' })
        } finally {
          this.isMoneyAccountLoading = false
        }
      }
    },
    hasUserType (role) {
      const { signedInUser } = this.$props
      return (signedInUser && isAuthorized(signedInUser, role))
    },
    setPhonePrefixByCountryId (countryId) {
      switch (countryId) {
        case 'GHA':
          this.phonePrefix = '+233'
          break
      }
    },
    hasTransportationRole (transportationRole) {
      const { signedInUser } = this.$props

      if (signedInUser && this.selectedUser) {
        return hasTransportationRoleFunc(signedInUser, transportationRole, this.selectedUser.transportationOwnerId, this.selectedUser)
      }
    },
    isEdited () {
      return !_isEqual(this.selectedUser, this.clonedSelectedUser)
    },
    getTransportationMobileMoneyAccounts (transportationUserIdentifiers) {
      return transportationUserIdentifiers.filter((o) => o.transportationUserMomoAccounts.length)
        .reduce((acc, currVal) => {
          const { transportationUserMomoAccounts, ...rest } = currVal
          acc = acc.concat(currVal.transportationUserMomoAccounts.map((o) => ({ ...o, transportationUserIdentifier: { ...rest } })))
          return acc
        }, [])
    },
    async loadUser (transportationOwnerUserId) {
      try {
        this.apiInProgress = true

        const transportationOwnerUser = await getTransportationOwnerUser({
          id: transportationOwnerUserId,
          filter: { include: ['stUser', 'transportationOwner', 'transportationUserMoneyAccounts', { transportationUserIdentifiers: 'transportationUserMomoAccounts' }] }
        })

        this.selectedUser = {
          ...transportationOwnerUser,
          firstName: transportationOwnerUser.stUser.firstName,
          lastName: transportationOwnerUser.stUser.lastName,
          email: transportationOwnerUser.stUser.email,
          phone: transportationOwnerUser.stUser.phone,
          transportationMobileMoneyAccounts: this.getTransportationMobileMoneyAccounts(transportationOwnerUser.transportationUserIdentifiers),
          verificationTokenExpiryLabel: transportationOwnerUser.verificationTokenExpiry ? `${formatTime(transportationOwnerUser.verificationTokenExpiry)}, ${formatDate(transportationOwnerUser.verificationTokenExpiry)}` : '',
          isExpired: transportationOwnerUser.verificationTokenExpiry && new Date(transportationOwnerUser.verificationTokenExpiry) < new Date(),
          isEditable: this.isUserEditable(transportationOwnerUser),
          userType: this.getUserTypeLabel(transportationOwnerUser.roles, 'returnValue'),
          excludeFeeLabel: !!(transportationOwnerUser.details && transportationOwnerUser.details.excludeFeeLabel)
        }

        this.setPhonePrefixByCountryId(this.selectedUser.transportationOwner.currentCountryId)
        this.clonedSelectedUser = _cloneDeep(this.selectedUser)
      } catch (err) {
        addAlert({ message: err, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    },
    cancelEdit () {
      if (this.isEdited()) {
        if (confirm('Are you sure you want to cancel editing?')) {
          this.$router.push({ name: 'transportation-accounts-drivers' })
        }
      } else {
        this.$router.push({ name: 'transportation-accounts-drivers' })
      }
    },
    async saveMobileMoneyUser () {
      if (!this.selectedMoneyAccount.transportationUserIdentifier?.phoneNumber || !this.selectedMoneyAccount.moneyAccountTypeId) {
        return addAlert({ message: 'Please enter mobile money number and money account type', type: 'error' })
      }

      try {
        this.isMoneyAccountLoading = true
        let result = null
        const transportationUserIdentifiersFound = this.selectedUser.transportationUserIdentifiers.find((o) => o.phoneNumber === this.selectedMoneyAccount.transportationUserIdentifier.phoneNumber)

        if (transportationUserIdentifiersFound) {
          result = await updateTransportationUserIdentifier({
            id: transportationUserIdentifiersFound.id,
            phoneNumber: this.selectedMoneyAccount.transportationUserIdentifier.phoneNumber,
            moneyAccountTypeId: this.selectedMoneyAccount.moneyAccountTypeId
          })
        } else {
          result = await createTransportationUserIdentifier({
            phoneNumber: this.selectedMoneyAccount.transportationUserIdentifier.phoneNumber,
            transportationOwnerUserId: this.selectedUser.id,
            moneyAccountTypeId: this.selectedMoneyAccount.moneyAccountTypeId
          })
        }

        addAlert({ message: `Successfully updated mobile money account ${this.selectedMoneyAccount.transportationUserIdentifier.phoneNumber}`, type: 'success' })
        const transportationUserIdentifierIndex = (this.selectedUser.transportationUserIdentifiers || []).findIndex((o) => o.id === result.id)

        if (transportationUserIdentifierIndex > -1) {
          this.selectedUser.transportationUserIdentifiers[transportationUserIdentifierIndex] = result
        } else {
          this.selectedUser.transportationUserIdentifiers.push(result)
        }

        this.selectedUser.transportationMobileMoneyAccounts = this.getTransportationMobileMoneyAccounts(this.selectedUser.transportationUserIdentifiers)

        this.clonedSelectedUser.transportationMobileMoneyAccounts = _cloneDeep(this.selectedUser.transportationMobileMoneyAccounts)
        this.clonedSelectedUser.transportationUserIdentifiers = _cloneDeep(this.selectedUser.transportationUserIdentifiers)
        this.moneyAccountModal = false
      } catch (err) {
        addAlert({ message: err, type: 'error' })
      } finally {
        this.isMoneyAccountLoading = false
      }
    },
    saveUser () {
      if (!this.selectedUser?.id) {
        return this.inviteUser()
      } else {
        return this.updateUser()
      }
    },
    async updateUser () {
      try {
        if (this.selectedUser.isEditable) {
          this.apiInProgress = true

          await updateTransportationOwnerUser({
            id: this.selectedUser.id,
            firstName: this.selectedUser.firstName,
            lastName: this.selectedUser.lastName,
            email: this.selectedUser.email,
            phone: !this.selectedUser.phone || this.selectedUser.phone === 'null' || this.selectedUser.phone === this.phonePrefix ? undefined : this.selectedUser.phone,
            role: this.selectedUser.userType,
            excludeFeeLabel: this.selectedUser.excludeFeeLabel
          })

          this.loadUser(this.selectedUser.id)

          addAlert({
            message: `Successfully updated user ${this.selectedUser.firstName} ${this.selectedUser.lastName}`,
            type: 'success'
          })
        }
      } catch (err) {
        addAlert({ message: err, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    },
    async inviteUser () {
      if (!this.$route.params.accountId) {
        return alert('Please select an account')
      }
      if (!this.selectedUser || (!this.selectedUser.email && !this.selectedUser.phone) || !this.selectedUser.firstName || !this.selectedUser.lastName || !this.selectedUser.userType) {
        return alert('One of email, phone, first name, last name or user type not provided')
      }
      if (!this.selectedUser.userType) {
        return alert('Please select a user type')
      }

      try {
        await createTransportationOwnerUser({
          transportationOwnerId: this.$route.params.accountId,
          email: this.selectedUser.verificationType === 'email' ? this.selectedUser.email : '',
          phone: this.selectedUser.verificationType === 'phone' ? this.selectedUser.phone : '',
          firstName: this.selectedUser.firstName,
          lastName: this.selectedUser.lastName,
          roles: [this.selectedUser.userType],
          verificationType: this.selectedUser.verificationType,
          noVerificationNotice: !this.selectedUser.verificationNotice
        })

        addAlert({ message: `Successfully ${this.selectedUser.noVerificationNotice ? 'created user' : 'sent invitation for'} ${this.selectedUser.firstName} ${this.selectedUser.lastName}`, type: 'success' })
        this.$router.push({ name: 'transportation-accounts-drivers' })
      } catch (err) {
        addAlert({ message: err, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    },
    async resendUserInvitation () {
      if (confirm(`Confirm re-sending invitation for ${this.selectedUser.stUser.firstName || ''} ${this.selectedUser.stUser.lastName}`)) {
        const { accountId } = this.$props.params

        try {
          this.apiInProgress = true
          await resendTransportationOwnerUserInvite({ transportationOwnerUserId: this.selectedUser.id, transportationOwnerId: accountId })
          addAlert({ message: `Successfully re-sent invitation for ${this.selectedUser.stUser.firstName || ''} ${this.selectedUser.stUser.lastName}`, type: 'success' })
        } catch (err) {
          addAlert({ message: `Error in re-sending invite for user: ${JSON.stringify(err)}`, type: 'error' })
        } finally {
          this.apiInProgress = false
        }
      }
    },
    async cancelInvitedUser () {
      if (confirm(`Confirm cancelling invitation for ${this.selectedUser.stUser.firstName || ''} ${this.selectedUser.stUser.lastName}`)) {
        await deleteTransportationOwnerUserInvite({ transportationOwnerUserId: this.selectedUser.id })
        addAlert({ message: `Successfully deleted invitation for ${this.selectedUser.stUser.firstName || ''} ${this.selectedUser.stUser.lastName}`, type: 'success' })
        this.$router.push({ name: 'transportation-accounts-drivers' })
      }
    },
    deleteUser (user) {
      if (user.isOwner || !user.isEditable) {
        return alert('Account owner cannot be deleted, please archive the account')
      }

      if (this.hasUserType('admin') || this.hasTransportationRole('OWNER')) {
        if (confirm(`Confirm permanently deleting "${user.stUser.firstName || ''} ${user.stUser.lastName}" from this account?`)) {
          this.apiInProgress = true

          deleteTransportationOwnerUser({
            id: user.id
          }).then(() => {
            addAlert({ message: `Successfully removed user ${user.stUser.firstName || ''} ${user.stUser.lastName} from account`, type: 'success' })
          }).catch((err) => {
            addAlert({ message: err, type: 'error' })
          }).finally(() => {
            this.apiInProgress = false
          })
        }
      }
    },
    getUserTypeLabel (roles, returnValue) {
      if (roles.indexOf('OWNER') > -1) {
        return returnValue ? 'OWNER' : 'Owner'
      }

      if (roles.indexOf('ADMIN') > -1) {
        return returnValue ? 'ADMIN' : 'Admin'
      }

      if (roles.indexOf('DRIVER') > -1) {
        return returnValue ? 'DRIVER' : 'Driver / Assistant'
      }
    },
    isUserEditable (user, ignoreIfEmailSame) {
      let isEditable = false
      user = user || this.selectedUser

      if ((!ignoreIfEmailSame && this.$props.signedInUser.email === user?.email) || this.$props.signedInUser?.email === ROOT_EMAIL) {
        isEditable = true
      } else if (user?.roles?.indexOf('OWNER') > -1 || user?.roles?.indexOf('ADMIN') > -1) {
        isEditable = this.hasUserType('admin') || this.hasTransportationRole('OWNER')
      } else {
        isEditable = this.hasTransportationRole('ADMIN')
      }

      return isEditable
    }
  },
  beforeDestroy () {
  }
}
