import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'
import { C_ADD_SITE_ALERT } from '@/utilities/mutation-types'
import {
  findValidPromotions,
  applyPromotion
} from '@/services/promotions-service'

import { formatCurrency } from '@smarttransit/common'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'
import { isAuthorized } from '@/utilities/helpers'
let _cachedComponentData = false

export default {
  name: 'promotions-promotion',
  props: {
    signedInUser: Object
  },
  data () {
    return {
      apiInProgress: false,
      validPromotionsApiInProgress: false,
      promotion: {
        selectedPromotion: null,
        selectedPassenger: null,
        amount: null,
        sendPromotionMessage: true,
        additionalPromotionalMessage: '',
        staffNote: ''
      },
      promotionOriginal: null,
      promotionError: null,
      promotionSearchKeywords: '',
      validPromotions: [],
      initialPassengerPhone: ''
    }
  },
  mounted: async function () {
    this.isSuperAdmin = isAuthorized(this.$props.signedInUser, 'superadmin')
    await this.onFilterValidPromotions()
    this.promotionOriginal = _cloneDeep(this.promotion)

    if (_cachedComponentData) {
      this.promotion = _cachedComponentData
    }

    if (this.$route.query && this.$route.query.phone) {
      this.initialPassengerPhone = decodeURIComponent(this.$route.query.phone)
    }
  },
  watch: {
    promotionSearchKeywords (val) {
      clearTimeout(this.promotionSearchKeywordsHandle)
      if (val) {
        this.promotionSearchKeywordsHandle = setTimeout(async () => {
          this.onFilterValidPromotions()
        }, 800)
      }
    }
  },
  methods: {
    isDataEdited () {
      return !_isEqual(this.promotion, this.promotionOriginal)
    },
    async onFilterValidPromotions () {
      try {
        let keywordsOr
        const where = { fileName: 'ad-hoc-credit-promotion' }
        if (this.promotionSearchKeywords) {
          keywordsOr = generateKeywordsQueryForOr(this.promotionSearchKeywords, ['customName', 'fileName'])
        }
        if (keywordsOr) {
          where.or = keywordsOr
        }
        this.validPromotionsApiInProgress = true
        this.validPromotions = await findValidPromotions({ filter: { where, limit: 10 }, timestamp: Date.now() })
        if (this.validPromotions && this.validPromotions.length) {
          const countryCurrencies = await this.$store.getters.getCountryCurrencies
          this.validPromotions.forEach((promotion) => {
            const currency = countryCurrencies && countryCurrencies[promotion.metadata.countryId] ? countryCurrencies[promotion.metadata.countryId].currencySymbol + ' ' : ''
            promotion.budgetLabel = formatCurrency(promotion.budget)
            promotion.budgetSpentLabel = `${currency}${formatCurrency(promotion.budgetSpent || 0)}`
          })
        }
        this.validPromotionsApiInProgress = false
      } catch (err) {
        this.validPromotionsApiInProgress = false
        if (err && !err.error) {
          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Error in loading valid promotions: ${err && err.message ? err.message : JSON.stringify(err)}`,
            type: 'error'
          })
        }
      }
    },
    validForm () {
      return this.$refs && this.$refs.form && this.$refs.form.validate()
    },
    async send () {
      if (this.validForm()) {
        try {
          this.apiInProgress = true
          const amountAsCents = this.promotion.amount

          if (amountAsCents > (this.promotion.selectedPromotion.budget - this.promotion.selectedPromotion.budgetSpent)) {
            throw new Error('Amount to credit is more than is available on this promotion')
          }

          await applyPromotion({
            id: this.promotion.selectedPromotion.id,
            amount: amountAsCents,
            passengerIdentifierId: this.promotion.selectedPassenger.id,
            doNotSendPromotionMessage: !this.promotion.sendPromotionMessage,
            additionalMessage: this.promotion.additionalPromotionalMessage,
            staffNote: this.promotion.staffNote
          })

          this.promotionOriginal = _cloneDeep(this.promotion)
          this.$router.push({ name: 'promotions' })
          alert('Promotion was successfully applied to ' + this.promotion.selectedPassenger.phoneNumber)
          this.apiInProgress = false
        } catch (err) {
          this.apiInProgress = false
          if (err && !err.error) {
            this.$store.commit(C_ADD_SITE_ALERT, {
              message: `Error in sending promotion credit: ${err && err.message ? err.message : JSON.stringify(err)}`,
              type: 'error'
            })
          }
        }
      } else {
        alert('Please check all required fields')
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.isDataEdited() || confirm('Discard changes made?')) {
      _cachedComponentData = null
      next()
    } else {
      _cachedComponentData = this.promotion
      next(false)
    }
  },
  beforeDestroy () {
    clearTimeout(this.promotionSearchKeywordsHandle)
  }
}
