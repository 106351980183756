import atigoghana from '../assets/airteltigo-money-logo.png'
import mtnghana from '../assets/mtn-money-logo.png'
import vodaghana from '../assets/vodafone-money-logo.jpg'
import { findCountryCurrencies } from '@/services/country-service'
import { C_SET_TOOLBAR_HEIGHT, C_SET_FOOTER_HEIGHT } from '@/utilities/mutation-types'

export default {
  state: {
    appToolbarHeight: 0,
    appFooterHeight: 0,
    moneyAccountLogos: {
      mtnghana,
      atigoghana,
      vodaghana
    },
    moneyAccountTypes: [
      { value: 'mtnghana', text: 'MTN Mobile Money', metadata: { src: mtnghana } },
      { value: 'atigoghana', text: 'Airtel/Tigo Money', metadata: { src: atigoghana } },
      { value: 'vodaghana', text: 'Vodafone Cash', metadata: { src: vodaghana } },
      { value: 'bankghana', text: 'Bank Account', metadata: { icon: 'fad fa-university' } }
    ],
    transportationRoles: [
      { text: 'Owner', value: 'OWNER' },
      { text: 'Admin', value: 'ADMIN' },
      { text: 'Driver / Assistant', value: 'DRIVER' }
    ],
    agencies: [
      { text: 'SmartTransit GHA', value: 'STRANSITGHA', metadata: { countryId: 'GHA' } },
      { text: 'GPRTU', value: 'GPRTU', metadata: { countryId: 'GHA' } },
      { text: 'PROTOA', value: 'PROTOA', metadata: { countryId: 'GHA' } }
    ],
    phoneNumberCountryCodes: [
      { code: '+233', title: 'Ghana', countryId: 'GHA' },
      { code: '+1', title: 'North America (Canada, US)', countryId: 'GHA' },
      { code: '+353', title: 'Ireland', countryId: 'GHA' },
      { code: '+44', title: 'United Kingdom', countryId: 'GHA' }
    ]
  },
  getters: {
    appToolbarHeight: (state) => state.appToolbarHeight,
    appFooterHeight: (state) => state.appFooterHeight,
    getMoneyAccountLogos: (state) => state.moneyAccountLogos,
    getTransportationRoles: (state) => state.transportationRoles,
    getAgencies: (state) => state.agencies,
    getMoneyAccountTypes: (state) => state.moneyAccountTypes,
    getCountryCurrencies: async (state) => {
      if (!state.countryCurrencies) {
        const countryCurrencies = await findCountryCurrencies()
        if (countryCurrencies && countryCurrencies.length) {
          state.countryCurrencies = {}
          countryCurrencies.forEach((currency) => {
            state.countryCurrencies[currency.countryId] = currency
          })
        }
      }
      return state.countryCurrencies
    },
    phoneNumberCountryCodes: (state) => state.phoneNumberCountryCodes
  },
  mutations: {
    [C_SET_TOOLBAR_HEIGHT] (state, value) {
      state.appToolbarHeight = value
    },
    [C_SET_FOOTER_HEIGHT] (state, value) {
      state.appFooterHeight = value
    }
  }
}
