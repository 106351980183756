import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

let CACHED_TEMPLATES = null
let CACHED_AGENT_CONVERSION_RATE = null
let CACHED_MONEY_ACCOUNT_TYPES = null

function getHoursOfOperationTemplates () {
  return apiRequests.get('/v1/st-setting/hours-of-operation/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    if (result && result.data) {
      result.data = result.data.map((o) => ({ ...o, ...o.data }))
    }

    CACHED_TEMPLATES = { timestamp: Date.now(), data: result.data } || null
    return result.data
  })
}

function getHoursOfOperationTemplate (id) {
  return apiRequests.get('/v1/st-setting/hours-of-operation/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    if (result && result.data) {
      result.data = { ...result.data, ...result.data.data }
    }

    return result.data
  })
}

function getCachedHoursOfOperationTemplates (cacheTimeoutMsecs = 15 * 60000) {
  if (!CACHED_TEMPLATES || CACHED_TEMPLATES.timestamp < (Date.now() + cacheTimeoutMsecs)) {
    return getHoursOfOperationTemplates()
  }

  return CACHED_TEMPLATES.data
}

function addHoursOfOperationTemplate ({ templateName, data }) {
  return apiRequests.post('/v1/st-setting/hours-of-operation/', { data: { templateName, data } }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateHoursOfOperationTemplate ({ id, templateName, data }) {
  return apiRequests.patch('/v1/st-setting/' + id + '/hours-of-operation/', { data: { templateName, data } }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getAgentConversionRatePercent ({ countryId }) {
  return apiRequests.get('/v1/st-setting/agent-conversion-rate/' + countryId + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    CACHED_AGENT_CONVERSION_RATE = { timestamp: Date.now(), data: result && result.data }
    return result && result.data
  })
}

function getCachedAgentConversionRatePercent ({ countryId, cacheTimeoutMsecs = 15 * 60000 }) {
  if (!CACHED_AGENT_CONVERSION_RATE || CACHED_AGENT_CONVERSION_RATE.timestamp < (Date.now() + cacheTimeoutMsecs)) {
    return getAgentConversionRatePercent({ countryId })
  }

  return CACHED_AGENT_CONVERSION_RATE.data
}

function deleteStSetting (id) {
  return apiRequests.delete('/v1/st-setting/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getMoneyAccountTypes () {
  return apiRequests.get('/v1/money-account-type/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    CACHED_MONEY_ACCOUNT_TYPES = result.data ? { timestamp: Date.now(), data: result.data } : null
    return result.data
  })
}

async function getCachedMoneyAccountTypes (cacheTimeoutMsecs = 15 * 60000, bustCache = false) {
  if (bustCache || !CACHED_MONEY_ACCOUNT_TYPES || CACHED_MONEY_ACCOUNT_TYPES.timestamp + cacheTimeoutMsecs < Date.now()) {
    return getMoneyAccountTypes()
  }

  return CACHED_MONEY_ACCOUNT_TYPES.data
}

export {
  getCachedMoneyAccountTypes,
  getCachedHoursOfOperationTemplates,
  getHoursOfOperationTemplates,
  getHoursOfOperationTemplate,
  getAgentConversionRatePercent,
  getCachedAgentConversionRatePercent,
  addHoursOfOperationTemplate,
  updateHoursOfOperationTemplate,
  deleteStSetting
}
