import { apiRequests, mapApiRequests } from '../utilities/axios-factory'
import store from '../store'

function findTransportationProfiles (filter) {
  if (filter.where && !Object.keys(filter.where).length) {
    filter.where = undefined
  }
  return apiRequests.get('/v1/transportation-profile/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalTransportationProfiles ({ where, join }) {
  if (where && !Object.keys(where).length) {
    where = undefined
  }
  return apiRequests.get('/v1/transportation-profile/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getTransportationProfile ({ id, filter }) {
  return apiRequests.get('/v1/transportation-profile/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteTransportationProfile ({ id }) {
  return apiRequests.delete('/v1/transportation-profile/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function recoverTransportationProfile ({ id }) {
  return apiRequests.delete('/v1/transportation-profile/' + id + '/recover/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateTransportationProfile (data) {
  return apiRequests.patch('/v1/transportation-profile/' + data.id + '/', data, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createTransportationProfile ({ transportationOwnerId, data }) {
  return apiRequests.post('/v1/transportation-owner/' + transportationOwnerId + '/transportation-profile/', data, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getGeoLocationTransportationProfilesWithinRadius ({ long, lat, searchradius, transportationOwnerId, includeTotal }) {
  return mapApiRequests.get('/v1/geo-location/transportation-profiles/', {
    params: { long, lat, searchradius, transportationOwnerId, includeTotal, includeOutOfOperationalHours: true },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getGeoLocationTransportationProfilesByIds ({ ids, limit }) {
  return mapApiRequests.get('/v1/geo-location/transportation-profiles/ids/', {
    params: { ids, limit },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getSimulateBusIds () {
  return mapApiRequests.get('/v1/geo-location/simulate-bus-ids/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function startTransportationSimulation ({ routeId, from, to, transportationProfileId }) {
  return mapApiRequests.put('/v1/geo-location/simulate-bus-route/', { routeId, from, to, transportationProfileId }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function stopTransportationSimulation ({ id }) {
  return mapApiRequests.delete('/v1/geo-location/stop-simulate-bus-route/?id=' + id, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getTotalTransportsWithHoursOfOperationTemplate (hoursOfOperationTemplateId) {
  return apiRequests.get('/v1/transportation-profile/hours-of-operation-template/' + hoursOfOperationTemplateId + '/count/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  findTransportationProfiles,
  findTotalTransportationProfiles,
  getTransportationProfile,
  deleteTransportationProfile,
  recoverTransportationProfile,
  updateTransportationProfile,
  createTransportationProfile,
  getGeoLocationTransportationProfilesWithinRadius,
  getGeoLocationTransportationProfilesByIds,
  getSimulateBusIds,
  startTransportationSimulation,
  stopTransportationSimulation,
  getTotalTransportsWithHoursOfOperationTemplate
}
