import { apiRequests } from '@/utilities/axios-factory'
import store from '../store'

function getPassengerTransactionLog ({ id, filter }) {
  return apiRequests.get('/v1/passenger-transaction-log/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findPassengerTransactionLogs (filter) {
  return apiRequests.get('/v1/passenger-transaction-log/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalPassengerTransactionLogs ({ where, join }) {
  return apiRequests.get('/v1/passenger-transaction-log/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findVendorTransactionLogs ({ vendor, filter, searchPhone, stUserKeywords }) {
  return apiRequests.get('/v1/passenger-transaction-log/vendor/' + vendor + '/', {
    params: { filter, searchPhone, stUserKeywords },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalVendorTransactionLogs ({ vendor, where, searchPhone, stUserKeywords }) {
  return apiRequests.get('/v1/passenger-transaction-log/vendor/' + vendor + '/count/', {
    params: { where, searchPhone, stUserKeywords },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findGeneralVendorTransactionLogs ({ filter, searchPhone }) {
  return apiRequests.get('/v1/passenger-transaction-log/vendor/', {
    params: { filter, searchPhone },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalGeneralVendorTransactionLogs ({ where, searchPhone }) {
  return apiRequests.get('/v1/passenger-transaction-log/vendor/count/', {
    params: { where, searchPhone },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getMoneyAccountTypes ({ countryId, typeId }) {
  return apiRequests.get('/v1/money-account-type/', {
    params: countryId || typeId ? { filter: { countryId, typeId } } : null,
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createAdHocRevenueTransaction ({ transportationProfileId, amount, dateCreated, notes, confirmationId }) {
  return apiRequests.post('/v1/transportation-profile/' + transportationProfileId + '/ad-hoc-revenue/', {
    amount,
    dateCreated,
    notes,
    confirmationId
  }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateAdHocRevenueTransaction ({ id, transportationProfileId, amount, dateCreated, notes, confirmationId }) {
  return apiRequests.patch('/v1/transportation-profile/' + transportationProfileId + '/ad-hoc-revenue/' + id, {
    amount,
    dateCreated,
    notes,
    confirmationId
  }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteAdHocRevenueTransaction ({ id, transportationProfileId }) {
  return apiRequests.delete('/v1/transportation-profile/' + transportationProfileId + '/ad-hoc-revenue/' + id, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTransactionsPayout ({ onlyPaid, onlyPending, fromDate, toDate, transportationProfileId, agencyId, transportationOwnerId, order, include, limit, offset }) {
  return apiRequests.get('/v1/passenger-transaction-log/payout/', {
    params: { onlyPaid, onlyPending, fromDate, toDate, transportationProfileId, agencyId, transportationOwnerId, order, include, limit, offset },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalTransactionsPayout ({ onlyPaid, onlyPending, fromDate, toDate, transportationProfileId, agencyId, transportationOwnerId }) {
  return apiRequests.get('/v1/passenger-transaction-log/payout/count/', {
    params: { onlyPaid, onlyPending, fromDate, toDate, transportationProfileId, agencyId, transportationOwnerId },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getMoneyAccountTypes,
  getPassengerTransactionLog,
  findPassengerTransactionLogs,
  findTotalPassengerTransactionLogs,
  findVendorTransactionLogs,
  findTotalVendorTransactionLogs,
  findGeneralVendorTransactionLogs,
  findTotalGeneralVendorTransactionLogs,
  createAdHocRevenueTransaction,
  updateAdHocRevenueTransaction,
  deleteAdHocRevenueTransaction,
  findTransactionsPayout,
  findTotalTransactionsPayout
}
