import { stMap } from '@smarttransit/common-map'
import { currencyField } from '@smarttransit/common-client'

import passengerTripsForm from './passenger-trips-form/template'
import phoneNumberField from './phone-number-field/template'
import registerForm from './register-form/template'
import signInForm from './sign-in-form/template'
import siteAlertsTray from './site-alerts-tray/template'
import transportationUserManagement from './transportation-user-management/template'
import staffManagement from './staff-management/template'
import agentManagement from './agent-management/template'
import downloadButton from './download-button/template'
import timezoneSelector from './timezone-selector/template'
import timeField from './time-field/template'
import passengerSelector from './passenger-selector/template'
import userSelector from './user-selector/template'
import hoursOfOperation from './hours-of-operation/template'
import stUploader from './st-uploader/template'

export default {
  'passenger-trips-form': passengerTripsForm,
  'phone-number-field': phoneNumberField,
  'register-form': registerForm,
  'sign-in-form': signInForm,
  'site-alerts-tray': siteAlertsTray,
  'st-map': stMap,
  'transportation-user-management': transportationUserManagement,
  'staff-management': staffManagement,
  'agent-management': agentManagement,
  'download-button': downloadButton,
  'timezone-selector': timezoneSelector,
  'time-field': timeField,
  'currency-field': currencyField,
  'passenger-selector': passengerSelector,
  'user-selector': userSelector,
  'hours-of-operation': hoursOfOperation,
  'st-uploader': stUploader
}
