import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getTransportationUserTransactionLog ({ id, filter }) {
  return apiRequests.get('/v1/transportation-user-transaction-log/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getPayoutReport ({ transportationUserMomoAccountId, transportationUserMoneyAccountId, dateStart, dateEnd, passengerTransactionLogIds, agencyId }) {
  return apiRequests.get('/v1/transportation-user-transaction-log/payout-report/', {
    params: { transportationUserMomoAccountId, transportationUserMoneyAccountId, dateStart, dateEnd, passengerTransactionLogIds, agencyId },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function payOwner ({ transportationUserMomoAccountId, transportationUserMoneyAccountId, dateStart, dateEnd, passengerTransactionLogIds, agencyId }) {
  return apiRequests.post('/v1/transportation-user-transaction-log/owner-payout/', { transportationUserMomoAccountId, transportationUserMoneyAccountId, dateStart, dateEnd, passengerTransactionLogIds, agencyId }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function markAsPaidToOwner ({ transportationUserMomoAccountId, transportationUserMoneyAccountId, dateStart, dateEnd, passengerTransactionLogIds, agencyId, notes }) {
  return apiRequests.post('/v1/transportation-user-transaction-log/owner-paid-out/', { transportationUserMomoAccountId, transportationUserMoneyAccountId, dateStart, dateEnd, passengerTransactionLogIds, agencyId, notes }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

/**
 * Utility function to call payout and poll to check when complete
 * @param {object} options
 * @param {string} options.transportationUserMomoAccountId
 * @param {string} options.transportationUserMoneyAccountId
 * @param {string|number} options.dateStart
 * @param {string|number} options.dateEnd
 * @param {array} options.passengerTransactionLogIds
 * @param {string} options.agencyId
 * @param {object} statusObj - e.g. { status } an object to check the status of the pending payout
 * @param {number} timeoutMsecs=20000
 * @returns {Promise<void>}
 */
async function payOwnerAndCheckUntilComplete ({ transportationUserMomoAccountId, transportationUserMoneyAccountId, dateStart, dateEnd, passengerTransactionLogIds, agencyId }, statusObj = {}, timeoutMsecs = 20000) {
  let iteration = 0
  async function checkingPayOwner (transaction, resolve, reject) {
    try {
      setTimeout(async () => {
        iteration += 1000
        statusObj.status = ''
        const updatedTransaction = await getTransportationUserTransactionLog({ id: transaction.id })
        statusObj.status = 'pending'

        if (updatedTransaction.logStatus === 'completed') {
          statusObj.status = 'completed'
          resolve(updatedTransaction)
        } else if (updatedTransaction.logStatus === 'error') {
          statusObj.status = 'error'
          throw new Error(`Transaction error: ${updatedTransaction.logMetadata.error}`)
        } else if (iteration >= timeoutMsecs) {
          throw new Error('Payout timed out')
        } else {
          await checkingPayOwner(transaction, resolve, reject)
        }
      }, 1000)
    } catch (err) {
      return reject(err)
    }
  }

  const transaction = await payOwner({ transportationUserMomoAccountId, transportationUserMoneyAccountId, dateStart, dateEnd, passengerTransactionLogIds, agencyId })

  if (transaction) {
    return new Promise((resolve, reject) => {
      checkingPayOwner(transaction, resolve, reject)
    })
  } else {
    throw new Error('No results returned for payOwner API call')
  }
}

function resendPayoutNotification (id) {
  return apiRequests.patch('/v1/transportation-user-transaction-log/' + id + '/notification/', null, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function salvagePayout ({ id, callbackParameters, moneyAccountTypeId, vendorProcessor, doNotSendNotification }) {
  return apiRequests.put('/v1/transportation-user-transaction-log/' + id + '/salvage/', { callbackParameters, moneyAccountTypeId, vendorProcessor, doNotSendNotification }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function cannotSalvagePayout ({ id }) {
  return apiRequests.put('/v1/transportation-user-transaction-log/' + id + '/unsalvageable/', null, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTransportationUserTransactionLogs (filter) {
  return apiRequests.get('/v1/transportation-user-transaction-log/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalTransportationUserTransactionLogs ({ where, join }) {
  return apiRequests.get('/v1/transportation-user-transaction-log/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getTransportationUserTransactionLog,
  findTransportationUserTransactionLogs,
  findTotalTransportationUserTransactionLogs,
  getPayoutReport,
  payOwner,
  payOwnerAndCheckUntilComplete,
  resendPayoutNotification,
  salvagePayout,
  cannotSalvagePayout,
  markAsPaidToOwner
}
