import _uniq from 'lodash.uniq'
import moment from 'moment-timezone'
import { formatCurrency, formatDate, formatTime } from '@smarttransit/common'
import { isAuthorized, addAlert } from '../../../utilities/helpers'

import {
  createAdHocRevenueTransaction,
  findTotalTransactionsPayout,
  findTransactionsPayout,
  updateAdHocRevenueTransaction
} from '../../../services/passenger-transaction-logs-service'

import { findTransportationProfiles } from '@/services/transportation-profiles-service'

export default {
  name: 'transportation-accounts-purchases',
  props: {
    signedInUser: Object,
    forceRootViewRefresh: Function,
    selectedAccount: Object
  },
  data () {
    return {
      apiInProgress: false,
      vehiclesApiInProgress: false,
      vehicleKeywords: '',
      adHocVehicleKeywords: '',
      countryCurrencies: {},
      selectedVehicle: null,
      dateFromMenu: null,
      dateFrom: null,
      dateToMenu: null,
      dateTo: null,
      vehicles: [],
      headers: [],
      transactions: [],
      totalTransactions: 0,
      selectedPaidFilter: '',
      adhocRevenueDialog: false,
      adhocRevenue: null,
      addingAdhocRevenue: false,
      adhocDate: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateCreated'
      }
    }
  },
  computed: {
    computedDateFromFormatted () {
      return this.dateFrom ? formatDate(this.dateFrom) : ''
    },
    computedDateToFormatted () {
      return this.dateTo ? formatDate(this.dateTo) : ''
    },
    computedDateFromMax () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null

      if (toDateAsMoment && (!fromDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return toDateAsMoment.subtract(1, 'day').format('Y-MM-DD')
      }

      return ''
    },
    computedDateToMin () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null

      if (fromDateAsMoment && (!toDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return fromDateAsMoment.add(1, 'day').format('Y-MM-DD')
      }

      return ''
    },
    computedAdHocDateFormatted () {
      return this.adhocRevenue.dateCreated ? formatDate(this.adhocRevenue.dateCreated) : ''
    },
    currentSelectedAccount () {
      return this.$props.selectedAccount
    },
    isAdmin () {
      if (this.$props.signedInUser) {
        return isAuthorized(this.$props.signedInUser, 'admin')
      }
    },
    isSuperAdmin () {
      if (this.$props.signedInUser) {
        return isAuthorized(this.$props.signedInUser, 'superadmin')
      }
    }
  },
  mounted () {
    if (this.$props.signedInUser) {
      this.isTransporter = this.$props.signedInUser.roles.find((o) => (o.name === 'transporter'))
    }

    this.headers = [
      {
        text: 'Amount',
        align: 'left',
        sortable: true,
        value: 'amount'
      },
      {
        text: 'Date',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Type',
        align: 'left',
        sortable: true,
        value: 'logType'
      },
      {
        text: 'Payout Status',
        align: 'left',
        sortable: true,
        value: 'transportationUserTransactionLogId'
      },
      {
        text: 'Vehicle',
        align: 'left',
        sortable: false
      },
      {
        text: 'Notes',
        align: 'left',
        sortable: false
      }
    ]

    this.$store.getters.getCountryCurrencies.then((countryCurrencies) => {
      this.countryCurrencies = countryCurrencies
    })

    this.firstApiCalled = true
    this.searchTransactions()

    this.selectedAccountWatchHandle = this.$watch(() => { return this.$props.selectedAccount }, (newVal, oldVal) => {
      if (!this.apiInProgress && (this.$route.params.accountId || this.$route.params.accountId === 0)) {
        this.firstApiCalled = true
        this.searchTransactions()
      }
    })

    if (this.$props.selectedAccount) {
      this.loadVehicles(this.$props.selectedAccount)
    }
  },
  watch: {
    vehicleKeywords (val) {
      clearTimeout(this.vehicleKeywordsTimeout)

      this.vehicleKeywordsTimeout = setTimeout(() => {
        if (val) {
          this.vehicles = (this.vehiclesLoaded || []).filter((o) => (new RegExp(`.*${val}.*`, 'ui')).test(o.licensePlateNumber))

          if (!this.vehicles.length) {
            this.vehicles = this.vehiclesLoaded.slice()
          }
        }
      }, 500)
    },
    adHocVehicleKeywords (val) {
      clearTimeout(this.adHocVehicleKeywordsTimeout)

      this.adHocVehicleKeywordsTimeout = setTimeout(() => {
        if (val) {
          this.vehicles = (this.vehiclesLoaded || []).filter((o) => (new RegExp(`.*${val}.*`, 'ui')).test(o.licensePlateNumber))

          if (!this.vehicles.length) {
            this.vehicles = this.vehiclesLoaded.slice()
          }
        } else {
          this.vehicles = this.vehiclesLoaded.slice()
        }
      }, 500)
    },
    selectedPaidFilter (val) {
      // prevent automatic call on first component load
      if (this.firstApiCalled) {
        if (val) {
          this.selectedVehicle = ''
        }

        this.searchTransactions()
      }
    },
    async selectedAccount (val) {
      if (val) {
        this.loadVehicles(val)
      } else {
        this.vehicles = []
        this.vehiclesLoaded = []
      }
    }
  },
  methods: {
    async loadVehicles (val) {
      try {
        const accountId = val?.id || null
        this.vehiclesApiInProgress = true

        this.vehiclesLoaded = await findTransportationProfiles({
          join: accountId ? {
            relation: 'transportationOwnerProfile',
            scope: { where: { transportationOwnerId: accountId } }
          } : undefined
        })

        this.vehicles = this.vehiclesLoaded.slice()
      } catch (err) {
        addAlert({ message: err, type: 'error' })
      } finally {
        this.vehiclesApiInProgress = false
      }
    },
    onFilterByVehicle () {
      if (this.firstApiCalled) {
        this.searchTransactions()
      }
    },
    onPagination () {
      if (this.firstApiCalled) {
        this.searchTransactions()
      }
    },
    openAdHocRevenueDialog () {
      this.adhocRevenue = {}
      this.adhocRevenueDialog = true
    },
    updateAdHocRevenueDialog (o) {
      this.adhocRevenue = {
        ...o,
        notes: o.logMetadata?.notes || '',
        confirmationId: o.logMetadata?.confirmationId || ''
      }

      this.adhocRevenueDialog = true
    },
    async saveAdhocRevenue () {
      if (!this.adhocRevenue.amount || this.adhocRevenue.amount <= 0) {
        if (isAuthorized(this.$props.signedInUser, 'superadmin') && this.adhocRevenue.amount === 0) {
          return alert('Please enter a valid amount other than 0')
        } else if (!isAuthorized(this.$props.signedInUser, 'superadmin') && this.adhocRevenue.amount < 0) {
          return alert('Please enter a valid amount greater than 0')
        }
      }

      if (!this.adhocRevenue.dateCreated) {
        return alert('Please select a date')
      }

      // call browser confirm dialog with number to enter to verify
      const confirmAmount = prompt(`Please enter the amount number ${formatCurrency(this.adhocRevenue.amount, null, '0.00')} to confirm the manual entry`)

      if (confirmAmount === formatCurrency(this.adhocRevenue.amount, null, '0.00')) {
        this.addingAdhocRevenue = true

        try {
          if (this.adhocRevenue.id) {
            await updateAdHocRevenueTransaction({
              id: this.adhocRevenue.id,
              transportationProfileId: this.adhocRevenue.transportationProfileId,
              amount: this.adhocRevenue.amount,
              dateCreated: moment(this.adhocRevenue.dateCreated, 'Y-MM-DD').utc().toDate().toISOString(),
              notes: this.adhocRevenue.notes,
              confirmationId: this.adhocRevenue.confirmationId
            })
          } else {
            console.warn('this.adhocRevenue=>', this.adhocRevenue)

            await createAdHocRevenueTransaction({
              transportationProfileId: this.adhocRevenue.transportationProfileId,
              amount: this.adhocRevenue.amount,
              dateCreated: moment(this.adhocRevenue.dateCreated, 'Y-MM-DD').utc().toDate().toISOString(),
              notes: this.adhocRevenue.notes,
              confirmationId: this.adhocRevenue.confirmationId
            })
          }

          this.adhocRevenueDialog = false
          this.searchTransactions()
        } catch (err) {
          addAlert({ message: err, type: 'error', isModal: true })
        } finally {
          this.addingAdhocRevenue = false
        }
      } else {
        alert('Amount entered does not match the amount to confirm')
      }
    },
    goToPayout () {
      if (this.currentSelectedAccount) {
        this.$router.push({ name: 'transportation-accounts-payouts-payout', params: { payoutId: '0' }, query: { dateFrom: this.dateFrom || undefined, dateTo: this.dateTo || undefined } })
      } else {
        alert('Please select an account first')
      }
    },
    marshallTransaction (o) {
      o.dateCreatedLabel = `${formatTime(o.dateCreated)}, ${formatDate(o.dateCreated)}`
      o.amountLabel = formatCurrency(o.logType === 'purchased_ticket' ? o.amount * -1 : o.amount, this.countryCurrencies[o.countryCurrencyId]?.currencySymbol)
      o.payoutLabel = o.logStatus === 'expired' || o.logStatus === 'confirmed' ? (o.transportationUserTransactionLogId ? 'Paid' : 'Pending') : 'n/a'
      o.notesLabel = '<span>' + (o.logType === 'ad_hoc_revenue' ? ((o.logMetadata.notes || '') + (o.logMetadata.confirmationId ? `\nConfirmation ID: ${o.logMetadata.confirmationId}` : '')).replace(/\n/g, '<br>') : '') + '</span>'
      return o
    },
    async searchTransactions () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage

      let params = {
        filter: {
          limit: rowsPerPage,
          offset
        }
      }

      if (this.selectedPaidFilter === 'onlypaid') {
        params.filter.onlyPaid = true
      } else if (this.selectedPaidFilter === 'pendingpaid') {
        params.filter.onlyPending = true
      }

      const accountId = this.currentSelectedAccount ? this.currentSelectedAccount.id : null

      if (!accountId) {
        throw new Error('Account needs to be selected to view revenue')
      }

      params.filter.transportationOwnerId = accountId

      if (this.selectedVehicle) {
        params.filter.transportationProfileId = this.selectedVehicle.id
      }

      params.filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`

      if (this.dateFrom) {
        params.filter.fromDate = moment.tz(this.dateFrom, 'Y-MM-DD', 'Africa/Accra').startOf('day').toDate().toISOString()
        // moment(this.dateFrom, 'Y-MM-DD').utc().toDate().toISOString()
      }

      if (this.dateTo) {
        params.filter.toDate = moment.tz(this.dateTo, 'Y-MM-DD', 'Africa/Accra').endOf('day').toDate().toISOString()
      }

      params.filter.include = {
        relation: 'passengerTrips',
        scope: {
          limit: 1
        }
      }

      this.apiInProgress = true

      try {
        const [transactions, totalTransactions] = await Promise.all([
          findTransactionsPayout(params.filter),
          findTotalTransactionsPayout(params.filter)
        ])

        this.transactions = transactions.map(o => {
          return this.marshallTransaction(o)
        })

        this.initTransportationOwnerNamesDisplay()
        this.totalTransactions = totalTransactions.count
      } catch (err) {
        addAlert({ message: err, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    },
    async initTransportationOwnerNamesDisplay () {
      try {
        if (this.transactions?.length) {
          this.currentTransportations = this.currentTransportations || []
          let transportationIdsToQuery = []

          this.transactions.forEach((transaction) => {
            let found
            const transportationProfileIdFromTransaction = transaction.transportationProfileId || transaction.passengerTrips[0].transportationProfileId

            for (let i = 0; i < this.currentTransportations.length; i++) {
              if (this.currentTransportations.find((o) => (o.transportationProfileId === transportationProfileIdFromTransaction))) {
                found = true
                break
              }
            }

            if (transportationProfileIdFromTransaction && !found) {
              transportationIdsToQuery.push(transportationProfileIdFromTransaction)
            }
          })

          transportationIdsToQuery = _uniq(transportationIdsToQuery)

          if (transportationIdsToQuery.length) {
            const transportationProfiles = await findTransportationProfiles({
              where: { id: { inq: transportationIdsToQuery } }
            })

            this.currentTransportations = this.currentTransportations.concat(transportationProfiles)
          }

          this.transactions = this.transactions.map((transaction, index) => {
            const transportationProfile = this.currentTransportations.find((o) => o.id === (transaction.transportationProfileId || transaction.passengerTrips[0].transportationProfileId))

            if (transportationProfile) {
              return { ...transaction, transportationProfileName: transportationProfile.licensePlateNumber }
            }

            return transaction
          })
        }
      } catch (err) {
        addAlert({ message: err, type: 'error' })
      }
    }
  },
  beforeDestroy () {
    if (this.selectedAccountWatchHandle) {
      this.selectedAccountWatchHandle()
    }
  }
}
