import moment from 'moment-timezone'
import _round from 'lodash.round'
import { formatCurrency, formatDate, formatTime } from '@smarttransit/common'
import { addParamsToLocation } from '@smarttransit/common-client'
import { addAlert, isAuthorized } from '../../../../utilities/helpers'

import {
  getPayoutReport,
  getTransportationUserTransactionLog, markAsPaidToOwner,
  payOwnerAndCheckUntilComplete
} from '../../../../services/transportation-user-transaction-logs-service'

import { getTransportationOwnerMomoAndMoneyAccounts } from '../../../../services/transportation-owner-users-service'
import { getUser } from '../../../../services/users-service'
import { getCachedMoneyAccountTypes } from '../../../../services/st-settings-service'

export default {
  name: 'transportation-accounts-payouts-payout',
  props: {
    signedInUser: Object,
    selectedAccount: Object,
    agencies: Array
  },
  data () {
    return {
      apiInProgress: false,
      dateFrom: null,
      dateFromMenu: null,
      dateTo: null,
      dateToMenu: null,
      currentPayout: null,
      report: null,
      reportError: '',
      agencyId: 'STRANSITGHA',
      moneyAccountId: null,
      transportationMoneyAccounts: [],
      markPaidApiInProgress: false
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      if (this.$route.query.agencyId) {
        this.agencyId = this.$route.query.agencyId
      }

      if (this.$route.params.payoutId && this.$route.params.payoutId !== '0') {
        this.getCurrentPayout(this.$route.params.payoutId)
      } else {
        if (this.$route.query.dateFrom) {
          this.dateFrom = this.$route.query.dateFrom
        }

        if (this.$route.query.dateFrom && this.$route.query.dateTo) {
          this.dateTo = this.$route.query.dateTo
        }

        if (this.$route.query.moneyAccountId) {
          this.moneyAccountId = this.$route.query.moneyAccountId
        }

        const moneyAccountTypes = await getCachedMoneyAccountTypes()
        let validTransportationOwnerUserIds = null

        if (!isAuthorized(this.$props.signedInUser, 'admin')) {
          validTransportationOwnerUserIds = this.$props.signedInUser.transportationOwnerUsers.filter((o) => (o.transportationOwnerId === this.$props.selectedAccount.id)).map((o) => o.id)
        }

        const { transportationMoneyAccounts, transportationMomoAccounts } = await getTransportationOwnerMomoAndMoneyAccounts({ transportationOwnerId: this.$route.params.accountId, transportationOwnerUserIds: validTransportationOwnerUserIds })
        const moneyAccountLogos = this.$store.getters.getMoneyAccountLogos

        if (transportationMomoAccounts.length || transportationMoneyAccounts.length) {
          this.transportationMoneyAccounts = transportationMomoAccounts.map((o) => {
            return {
              text: o.transportationUserIdentifier.phoneNumber,
              metadata: {
                src: moneyAccountLogos ? moneyAccountLogos[o.moneyAccountTypeId] : null,
                moneyAccountTypeId: o.moneyAccountTypeId,
                moneyAccountTypeCategory: moneyAccountTypes.find((m) => m.typeId === o.moneyAccountTypeId)?.typeCategory
              },
              value: o.id
            }
          })

          this.transportationMoneyAccounts = this.transportationMoneyAccounts.concat(transportationMoneyAccounts.map((o) => {
            return {
              text: o.externalId,
              metadata: {
                icon: this.$store.getters.getMoneyAccountTypes.find((m) => m.value === o.moneyAccountTypeId).metadata.icon,
                moneyAccountTypeId: o.moneyAccountTypeId,
                accountMetadata: o.accountMetadata,
                moneyAccountTypeCategory: moneyAccountTypes.find((m) => m.typeId === o.moneyAccountTypeId)?.typeCategory
              },
              value: o.id
            }
          }))

          if (this.transportationMoneyAccounts.length === 1) {
            this.moneyAccountId = this.transportationMoneyAccounts[0].value
          }

          // generate report based on dates provided
          if (this.dateFrom && this.agencyId) {
            this.getPendingPayoutReport()
          }
        }
      }
    })
  },
  watch: {
    dateFrom (val) {
      this.lastParams = addParamsToLocation({ dateFrom: val }, this.$route, this.lastParams)
    },
    dateTo (val) {
      this.lastParams = addParamsToLocation({ dateTo: val }, this.$route, this.lastParams)
    },
    moneyAccountId (val) {
      this.lastParams = addParamsToLocation({ moneyAccountId: val }, this.$route, this.lastParams)
    }
  },
  computed: {
    computedDateFromFormatted () {
      return this.dateFrom ? formatDate(this.dateFrom) : ''
    },
    computedDateToFormatted () {
      return this.dateTo ? formatDate(this.dateTo) : ''
    },
    computedDateFromMax () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null

      if (toDateAsMoment && (!fromDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return toDateAsMoment.subtract(1, 'day').format('Y-MM-DD')
      }

      return ''
    },
    computedDateToMin () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null

      if (fromDateAsMoment && (!toDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return fromDateAsMoment.add(1, 'day').format('Y-MM-DD')
      }

      return ''
    },
    isSuperAdmin () {
      return isAuthorized(this.$props.signedInUser, 'superadmin')
    },
    currentSelectedAccount () {
      return this.$props.selectedAccount
    },
    currentAgencies () {
      return this.$props.agencies
    },
    currentMomoAccountLabel () {
      if (this.transportationMoneyAccounts.length && this.moneyAccountId) {
        return this.transportationMoneyAccounts.find(o => o.value === this.moneyAccountId).text
      }

      return ''
    },
    currentCategoryType () {
      if (this.transportationMoneyAccounts.length && this.moneyAccountId) {
        return this.transportationMoneyAccounts.find(o => o.value === this.moneyAccountId)?.metadata?.moneyAccountTypeCategory || ''
      }

      return ''
    }
  },
  methods: {
    async getCurrentPayout (id) {
      try {
        this.apiInProgress = true
        this.currentPayout = await getTransportationUserTransactionLog({ id, filter: { include: ['transportationUserMoneyAccount', { transportationUserMomoAccount: 'transportationUserIdentifier' }] } })

        this.report = await this.marshallReport({
          [this.currentPayout.logMetadata.agencyId]: {
            agencyId: this.currentPayout.logMetadata.agencyId,
            fee: this.currentPayout.logMetadata.feePercentage,
            grossPayout: this.currentPayout.logMetadata.grossPayout,
            feeDeduction: this.currentPayout.logMetadata.feeDeduction,
            payout: this.currentPayout.amount,
            total: this.currentPayout.logMetadata.total,
            currencySymbol: this.currentPayout.logMetadata.currencySymbol,
            dateStart: this.currentPayout.logMetadata.dateStart,
            dateEnd: this.currentPayout.logMetadata.dateEnd,
            byStUserId: this.currentPayout.logMetadata.byStUserId,
            notes: this.currentPayout.logMetadata.notes
          }
        })

        this.apiInProgress = false
      } catch (err) {
        // console.error(err)
        this.apiInProgress = false
      }
    },
    async marshallReport (report) {
      const users = {}

      for (const key in report) {
        if (report[key]) {
          report[key].agencyLabel = this.$props.agencies.find(o => o.value === report[key].agencyId).text
          report[key].grossPayoutLabel = `${report[key].currencySymbol}${formatCurrency(report[key].grossPayout)}`
          report[key].payoutLabel = `${report[key].currencySymbol}${formatCurrency(report[key].payout)}`
          report[key].feeLabel = `${_round(report[key].fee * 100, 2)}%`
          report[key].feeDeductionLabel = `${report[key].currencySymbol}${formatCurrency(report[key].feeDeduction)}`
          report[key].dateStartLabel = report[key].dateStart ? `${formatTime(report[key].dateStart)} ${formatDate(report[key].dateStart, moment.tz.guess(), { displayTimezone: Boolean(!report[key].dateEnd) })}` : 'All pending payouts'
          report[key].dateEndLabel = report[key].dateEnd && formatDate(report[key].dateEnd) !== formatDate(report[key].dateStart) ? `${formatTime(report[key].dateEnd)} ${formatDate(report[key].dateEnd, moment.tz.guess(), { displayTimezone: Boolean(report[key].dateStart) })}` : ''

          if (this.currentPayout) {
            report[key].dateUpdatedLabel = formatTime(this.currentPayout.dateUpdated) + ' ' + formatDate(this.currentPayout.dateUpdated, moment.tz.guess(), { displayTimezone: true })
          }

          if (report[key].byStUserId) {
            try {
              const user = users[report[key].byStUserId] || await getUser({ id: report[key].byStUserId, isDeleted: true })
              report[key].byStUserLabel = [user.firstName, user.lastName].filter(Boolean).join(' ') || user.email
              users[report[key].byStUserId] = user
            } catch (err) {
              addAlert({ message: err, type: 'error' })
              report[key].byStUserLabel = `ID: ${report[key].byStUserId}`
            }
          }

          if (this.currentPayout && report[key].notes) {
            report[key].notesLabel = report[key].notes.replace(/\n/g, '<br>')
          }
        }
      }

      return report
    },
    async markAsPaid () {
      const promptResult = prompt('This will mark all tickets under this agency as paid without any system payouts.\n' +
        'This is useful when payout has been rendered outside the system.\n' +
        'Please enter a deposit/confirmation number or enter "paid" to confirm marking the set period as paid')

      if ((promptResult || '').trim()) {
        try {
          this.markPaidApiInProgress = true
          const selectedMoneyAccount = this.transportationMoneyAccounts.find(o => o.value === this.moneyAccountId)

          const confirmedTransaction = await markAsPaidToOwner({
            transportationUserMomoAccountId: selectedMoneyAccount.metadata.moneyAccountTypeCategory === 'mobile_money' ? this.moneyAccountId : undefined,
            transportationUserMoneyAccountId: selectedMoneyAccount.metadata.moneyAccountTypeCategory === 'bank' ? this.moneyAccountId : undefined,
            dateStart: this.dateFrom ? moment.tz(this.dateFrom, 'Africa/Accra').startOf('day').valueOf() : null,
            dateEnd: this.dateTo ? moment.tz(this.dateTo, 'Africa/Accra').endOf('day').valueOf() : null,
            agencyId: this.agencyId,
            notes: (promptResult || '').trim() === 'paid' ? '' : promptResult.trim()
          })

          addAlert({
            message: `Successfully marked as paid`,
            type: 'success',
            transient: true
          })

          this.$router.push({
            name: 'transportation-accounts-payouts-payout',
            params: { payoutId: confirmedTransaction.id }
          })
        } catch (err) {
          this.reportError = `Error in marking as paid: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
        } finally {
          this.markPaidApiInProgress = false
        }
      }
    },
    async requestPayout () {
      if (confirm('Please confirm making a payout to: ' + this.currentSelectedAccount.companyName + ' - ' + this.currentMomoAccountLabel)) {
        try {
          this.apiInProgress = true
          const selectedMoneyAccount = this.transportationMoneyAccounts.find(o => o.value === this.moneyAccountId)

          const confirmedTransaction = await payOwnerAndCheckUntilComplete({
            transportationUserMomoAccountId: selectedMoneyAccount.metadata.moneyAccountTypeCategory === 'mobile_money' && this.moneyAccountId,
            transportationUserMoneyAccountId: selectedMoneyAccount.metadata.moneyAccountTypeCategory === 'bank' && this.moneyAccountId,
            dateStart: this.dateFrom ? moment.tz(this.dateFrom, 'Africa/Accra').startOf('day').valueOf() : null,
            dateEnd: this.dateTo ? moment.tz(this.dateTo, 'Africa/Accra').endOf('day').valueOf() : null,
            agencyId: this.agencyId
          })

          addAlert({
            message: `Successfully paid owner`,
            type: 'success',
            transient: true
          })

          this.$router.push({
            name: 'transportation-accounts-payouts-payout',
            params: { payoutId: confirmedTransaction.id }
          })
        } catch (err) {
          this.reportError = `Error in payout: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
        } finally {
          this.apiInProgress = false
        }
      }
    },
    async getPendingPayoutReport () {
      try {
        this.reportError = ''

        if (this.moneyAccountId && this.agencyId) {
          this.apiInProgress = true
          const selectedMoneyAccount = this.transportationMoneyAccounts.find(o => o.value === this.moneyAccountId)

          const result = await getPayoutReport({
            transportationUserMomoAccountId: selectedMoneyAccount.metadata.moneyAccountTypeCategory === 'mobile_money' ? this.moneyAccountId : null,
            transportationUserMoneyAccountId: selectedMoneyAccount.metadata.moneyAccountTypeCategory === 'bank' ? this.moneyAccountId : null,
            dateStart: this.dateFrom ? moment.tz(this.dateFrom, 'Africa/Accra').startOf('day').valueOf() : null,
            dateEnd: this.dateTo ? moment.tz(this.dateTo, 'Africa/Accra').endOf('day').valueOf() : null,
            agencyId: this.agencyId
          })

          this.report = await this.marshallReport(result.report)

          if (!this.report) {
            this.reportError = 'Based on the options, no unpaid revenue were found to generate a report'
          }
        } else {
          const fields = [
            // { key: '"Date from"', value: this.dateFrom },
            { key: '"Agency ID"', value: this.agencyId },
            { key: '"Money account"', value: this.moneyAccountId }]
            .filter(o => !o.value)
            .map(o => o.key).join(', ')

          addAlert({
            message: `The following required field(s) are missing: ${fields}`,
            type: 'error'
          })
        }
      } catch (err) {
        // console.error(err)
        this.reportError = `Error in payout report: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
      } finally {
        this.apiInProgress = false
      }
    },
    onFilterByAgency (val) {
      this.selectedAgency = val
    }
  },
  beforeDestroy () {
    if (this.$router.currentRoute.name !== 'transportation-accounts-payouts-payout') {
      if (this.lastParams && Object.keys(this.lastParams).length) {
        addParamsToLocation({ dateFrom: null, dateTo: null, agencyId: null, moneyAccountId: null }, this.$route, this.lastParams)
      }
    }
  }
}
