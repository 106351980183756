<template>
  <div class="transportation-user">
    <div class="layout justify-end">
      <v-btn color="warning mr-2" flat @click="cancelEdit" :disabled="apiInProgress">Cancel</v-btn>
      <v-btn color="primary" @click="saveUser" v-if="isUserEditable()" :disabled="apiInProgress || !isEdited()" :loading="apiInProgress">
        {{selectedUser && selectedUser.id ? 'Save' : 'Invite'}}
      </v-btn>
    </div>
    <v-layout row wrap v-if="selectedUser">
      <v-flex mb-5 pr-2 md8>
        <v-layout row wrap mb-1 v-if="selectedUser.stUser && selectedUser.stUser.dateArchived">
          <v-flex class="text-xs-right align-center font-weight-bold" sm4 pr-3>
            <span>Date archived</span>
          </v-flex>
          <v-flex sm8>
            <v-chip>{{selectedUser.dateArchivedLabel}}</v-chip>
            <div v-show="isAdmin"><v-btn small color="warning" @click="onUndoArchive">Un-archive</v-btn></div>
          </v-flex>
        </v-layout>
        <v-layout row wrap mb-1 v-if="!selectedUser.id || selectedUser.verificationTokenExpiry">
          <v-flex class="text-xs-right align-content-center font-weight-bold" sm4 pr-3>
            <span>Invite by</span>
          </v-flex>
          <v-flex sm8>
            <v-select
              pb-2
              outline
              :disabled="apiInProgress || !isUserEditable()"
              :items="[{text: 'Email', value: 'email'},{text: 'Phone', value: 'phone'}]"
              placeholder="Select invitation type"
              v-model="selectedUser.verificationType"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap mb-1 v-if="selectedUser.verificationTokenExpiry">
          <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
            <span>Invitation expiry</span>
          </v-flex>
          <v-flex sm8>
            {{selectedUser.verificationTokenExpiryLabel}} <span style="color: red" v-show="selectedUser.isExpired">Expired</span>
            <div><v-btn small :disabled="!selectedUser.isEditable" @click="resendUserInvitation()">Re-send invitation</v-btn></div>
            <div><v-btn small :disabled="!selectedUser.isEditable" @click="cancelInvitedUser()">Cancel invitation</v-btn></div>
          </v-flex>
        </v-layout>
        <v-layout row wrap mb-1>
          <v-flex class="text-xs-right align-content-center font-weight-bold" sm4 pr-3>
            <span>User type</span>
          </v-flex>
          <v-flex sm8>
            <v-select
              pb-2
              outline
              :disabled="!isUserEditable(null, 'ignoreIfEmailSame')"
              :items="transportationRoles"
              v-model="selectedUser.userType"
              placeholder="Select user type"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap mb-1>
          <v-flex class="text-xs-right align-content-center font-weight-bold" sm4 pr-3>
            <span>First name</span>
          </v-flex>
          <v-flex sm8>
            <v-text-field
              pb-2
              :readonly="!isUserEditable()"
              type="email"
              v-model="selectedUser.firstName"
              placeholder="Enter first name"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap mb-1>
          <v-flex class="text-xs-right align-content-center font-weight-bold" sm4 pr-3>
            <span>Last name</span>
          </v-flex>
          <v-flex sm8>
            <v-text-field
              pb-2
              :readonly="!isUserEditable()"
              v-model="selectedUser.lastName"
              placeholder="Enter last name"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap mb-1>
          <v-flex class="text-xs-right align-content-center font-weight-bold" sm4 pr-3>
            <span>Email address</span>
          </v-flex>
          <v-flex sm8>
            <v-text-field
              pb-2
              :readonly="!isUserEditable()"
              type="email"
              v-model="selectedUser.email"
              :rules="[(v) => (!v || /^[^@]+@[^@]+\.[0-9a-zA-Z]{2,}$/.test(v) || 'Valid email format required')]"
              placeholder="Enter email address"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap mb-1>
          <v-flex class="text-xs-right align-content-center font-weight-bold" sm4 pr-3>
            <span>Phone number</span>
          </v-flex>
          <v-flex sm8>
            <phone-number-field
              pb-2
              :props-prefix="phonePrefix"
              v-model="selectedUser.phone"
              :child-props="{ label: 'Phone', readonly: !isUserEditable() }"
            />
          </v-flex>
        </v-layout>
        <v-layout v-if="selectedUser && selectedUser.userType === 'OWNER' && hasUserType('admin')" row justify-start align-center wrap>
          <v-flex class="text-xs-right align-content-center font-weight-bold" sm4 pr-3>
            <span>Exclude deduction fee in report</span>
          </v-flex>
          <v-flex sm8>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn style="margin: 0" flat icon><v-icon v-on="on">fal fa-info-circle</v-icon></v-btn>
              </template>
              <span>Will not display the SmartTransyt fee deduction in the SMS/email report sent to the account owner</span>
            </v-tooltip>
            <v-switch v-model="selectedUser.excludeFeeLabel" class="ml-2" label="Excluded" />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex mb-5 px-2 md4 v-if="selectedUser.id">
        <h3 class="mb-2 text-xs-center"><strong>Money Accounts</strong></h3>
        <div class="mb-2 layout justify-space-between">
          <v-btn class="mr-2" @click="loadMoneyAccountModal({ moneyAccountTypeId: unfilteredMoneyAccountTypes.find((o) => o.typeCategory === 'mobile_money').typeId })" block small><small>Add Mobile Money</small></v-btn>
          <v-btn @click="loadMoneyAccountModal({ moneyAccountTypeId: unfilteredMoneyAccountTypes.find((o) => o.typeCategory === 'bank').typeId })" block small><small>Add Account</small></v-btn>
        </div>
        <v-card>
          <v-list two-line subheader>
            <div
              v-for="(transportationMobileMoneyAccount, index) in selectedUser.transportationMobileMoneyAccounts"
              :key="`mobileMoney${index}`"
            >
              <v-list-tile
                avatar
                @click="loadMoneyAccountModal(transportationMobileMoneyAccount)"
              >
                <v-list-tile-avatar>
                  <img
                    style="height: 30px;"
                    :src="moneyAccountTypes.find((o) => o.value === transportationMobileMoneyAccount.moneyAccountTypeId).metadata.src"
                    :alt="moneyAccountTypes.find((o) => o.value === transportationMobileMoneyAccount.moneyAccountTypeId).text"
                  />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{transportationMobileMoneyAccount.transportationUserIdentifier.phoneNumber}}</v-list-tile-title>
                  <v-list-tile-sub-title>{{moneyAccountTypes.find((o) => o.value === transportationMobileMoneyAccount.moneyAccountTypeId).text}}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn icon @click.stop="deregisterMomoAccount(transportationMobileMoneyAccount.id, transportationMobileMoneyAccount.transportationUserIdentifier.phoneNumber)" :disabled="!isUserEditable()">
                    <v-icon color="error" small>fas fa-trash</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider inset />
            </div>
            <div
              v-for="(transportationUserMoneyAccount, index) in selectedUser.transportationUserMoneyAccounts"
              :key="`moneyAccount${index}`"
            >
              <v-list-tile
                avatar
                @click="loadMoneyAccountModal(transportationUserMoneyAccount)"
              >
                <v-list-tile-avatar>
                  <v-icon large>{{moneyAccountTypes.find((o) => o.value === transportationUserMoneyAccount.moneyAccountTypeId).metadata.icon}}</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{transportationUserMoneyAccount.externalId}}</v-list-tile-title>
                  <v-list-tile-sub-title v-if="getMoneyAccountCategory(transportationUserMoneyAccount.moneyAccountTypeId) === 'bank'">{{transportationUserMoneyAccount.accountMetadata.bankName}}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn icon @click.stop="archiveMoneyAccount(transportationUserMoneyAccount.id, transportationUserMoneyAccount.externalId)" :disabled="!isUserEditable()">
                    <v-icon color="error" small>fas fa-trash</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider inset />
            </div>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="layout justify-end">
      <v-btn color="warning mr-2" flat @click="cancelEdit" :disabled="apiInProgress">Cancel</v-btn>
      <v-btn color="primary" @click="saveUser" v-if="isUserEditable()" :disabled="apiInProgress || !isEdited()" :loading="apiInProgress">
        {{selectedUser && selectedUser.id ? 'Save' : 'Invite'}}
      </v-btn>
    </div>
    <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        persistent
        max-width="500"
        v-model="moneyAccountModal">
      <v-card v-if="selectedMoneyAccount">
        <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
          Manage Money Account
        </v-card-title>
        <div class="pa-2">
          <v-select
            pb-2
            outline
            :disabled="Boolean(selectedMoneyAccount.id)"
            :items="moneyAccountTypes"
            v-model="selectedMoneyAccount.moneyAccountTypeId"
            label="Money account type"
          >
            <template slot="item" slot-scope="{ item }">
              <div class="layout row justify-start align-center">
                <div>{{item.text}}</div>
                <div v-if="item.metadata.src" class="ml-2">
                  <img :src="item.metadata.src" style="height: 30px;" :alt="item.value" />
                </div>
                <div v-if="item.metadata.icon" class="ml-2">
                  <v-icon medium>{{item.metadata.icon}}</v-icon>
                </div>
              </div>
            </template>
            <template slot="selection" slot-scope="{ item }">
              <div class="layout row justify-start align-center">
                <div class="layout row justify-start align-center">
                  <div>{{item.text}}</div>
                  <div v-if="item.metadata.src" class="ml-2">
                    <img :src="item.metadata.src" style="height: 30px;" :alt="item.value" />
                  </div>
                  <div v-if="item.metadata.icon" class="ml-2">
                    <v-icon medium>{{item.metadata.icon}}</v-icon>
                  </div>
                </div>
              </div>
            </template>
          </v-select>
          <phone-number-field
            pb-2
            v-if="selectedMoneyAccountCategory === 'mobile_money'"
            :props-prefix="phonePrefix"
            v-model="selectedMoneyAccount.transportationUserIdentifier.phoneNumber"
            :child-props="{ label: 'Mobile money number' }"
          />
          <div v-if="selectedMoneyAccountCategory === 'bank'">
            <v-text-field
              pb-2
              :readonly="!isUserEditable()"
              v-model="selectedMoneyAccount.externalId"
              label="Bank account number"
            />
            <v-text-field
              pb-2
              :readonly="!isUserEditable()"
              v-model="selectedMoneyAccount.accountMetadata.bankName"
              label="Bank name"
            />
            <v-text-field
              pb-2
              :readonly="!isUserEditable()"
              v-model="selectedMoneyAccount.accountMetadata.accountFullname"
              label="Account holder full name"
            />
            <v-text-field
              pb-2
              persistent-hint
              :readonly="!isUserEditable()"
              v-model="selectedMoneyAccount.accountMetadata.swiftCode"
              label="Swift/BIC code"
              hint="(Optional)"
            />
            <v-textarea
              pb-2
              persistent-hint
              rows="2"
              :readonly="!isUserEditable()"
              v-model="selectedMoneyAccount.accountMetadata.bankAddress"
              label="Bank address"
              hint="(Optional)"
            />
          </div>
        </div>
        <v-divider />
        <v-card-actions>
          <v-btn flat color="error" @click.stop="archiveSelectedMoneyAccount()" :disabled="isMoneyAccountLoading || !isUserEditable() || !selectedMoneyAccount.id">
            <v-icon small left>fas fa-trash</v-icon> Remove
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" :disabled="isMoneyAccountLoading" flat @click="moneyAccountModal = false">
            Cancel
          </v-btn> &nbsp;
          <v-btn :disabled="isMoneyAccountLoading" :loading="isMoneyAccountLoading" @click="saveMoneyAccount()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
