<template>
  <div class="transportation-accounts-payouts">
    <div v-if="$router.currentRoute.name === 'transportation-accounts-payouts'">
      <form @submit.prevent="searchPayouts">
        <div class="justify-start align-center layout">
          <v-btn @click="goToPayoutSchedule">
            <v-icon small>fal fa-calendar-day</v-icon> &nbsp; Payout scheduler
          </v-btn>
          <v-btn @click="goToPayout" class="ml-2">
            <v-icon small>fal fa-envelope-open-dollar</v-icon> &nbsp; Make a payout
          </v-btn>
        </div>
        <v-layout row wrap>
          <v-flex sm4 pa-2>
            <v-text-field
              solo
              v-model="searchId"
              append-icon="fal fa-search"
              :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
              :disabled="apiInProgress"
              type="search"
              placeholder="Search by last 7 digits or full transaction id"
              @keyup.enter="searchPayouts"
              @click:append="searchPayouts"
            />
          </v-flex>
          <v-flex sm2 pa-2>
            <v-select
              clearable
              v-model="agencyId"
              :items="agencies"
              label="Agency"
            />
          </v-flex>
          <v-flex sm5>
            <v-layout>
              <v-flex xs6 pa-2>
                <v-menu
                  v-model="dateFromMenu"
                  :close-on-content-click="false"
                  full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      prepend-icon="fal fa-calendar"
                      :value="computedDateFromFormatted"
                      @click:clear="dateFrom = null"
                      clearable
                      label="From"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    no-title
                    :max="computedDateFromMax"
                    v-model="dateFrom"
                    @change="dateFromMenu = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs6 pa-2>
                <v-menu
                  v-model="dateToMenu"
                  :close-on-content-click="false"
                  full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      prepend-icon="fal fa-calendar"
                      :value="computedDateToFormatted"
                      @click:clear="dateTo = null"
                      clearable
                      label="To"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    no-title
                    :min="computedDateToMin"
                    v-model="dateTo"
                    @change="dateToMenu = false"
                  />
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm1 pa-2>
            <v-btn small fab @click="searchPayouts"><v-icon small>fal fa-filter</v-icon></v-btn>
          </v-flex>
        </v-layout>
      </form>
      <v-data-table
        :headers="headers"
        :items="payouts"
        :pagination.sync="pagination"
        :total-items="totalPayouts"
        :loading="apiInProgress"
        :rows-per-page-items="[10, 25, 50]"
        @update:pagination="onPagination"
        class="elevation-1"
        must-sort
      >
        <template v-slot:items="props">
          <tr @click="goToTransactionReport(props.item.id)" class="tr--pointer">
            <td :title="props.item.id">
              <v-text-field
                class="text-field--flat"
                :value="props.item.id"
                readonly
                hide-details
              />
              <v-chip v-if="props.item.transportationOwnerCompanyName" :title="props.item.fullTransportationOwnerCompanyName" small><small>{{props.item.transportationOwnerCompanyName}}</small></v-chip>
            </td>
            <td>
              {{props.item.amountLabel}}
            </td>
            <td>
              <div class="layout row align-center justify-start">
                <v-chip :color="props.item.logStatusParams.color" :text-color="props.item.logStatusParams.color ? 'white' : undefined" small>
                  <v-icon small>{{props.item.logStatusParams.icon}}</v-icon> &nbsp;
                  <small>{{props.item.logStatus}}</small>
                </v-chip>
                <v-icon v-if="props.item.logMetadata.salvagedByStUser" title="Payout was salvaged" color="success" small class="ml-1">far fa-recycle</v-icon>
              </div>
              <div v-show="props.item.logStatus === 'timedout' && isAdmin">
                <v-btn small v-show="!props.item.logMetadata.notSalvagedByStUser" @click.stop="goToSalvage(props.item.id)" class="mt-2">
                  <v-icon small>fa fa-trash-undo-alt</v-icon> &nbsp; <small>Salvage</small>
                </v-btn>
                <v-btn small v-show="props.item.logMetadata.notSalvagedByStUser" @click.stop="goToSalvage(props.item.id)" class="mt-2">
                  <v-icon small>fa fa-ban</v-icon> &nbsp; <small>Could not salvage</small>
                </v-btn>
              </div>
            </td>
            <td>
              <div v-if="props.item.transportationUserMomoAccount">
                <small>{{props.item.transportationUserMomoAccount.transportationUserIdentifier.phoneNumber}}</small>
                <div>
                  <img v-if="props.item.transportationUserMomoAccount.moneyAccountTypeId" style="height: 30px;" :src="moneyAccountLogos[props.item.transportationUserMomoAccount.moneyAccountTypeId]" :title="props.item.transportationUserMomoAccount.moneyAccountTypeId" :alt="props.item.transportationUserMomoAccount.moneyAccountTypeId" />
                </div>
              </div>
              <div v-if="props.item.transportationUserMoneyAccount">
                <small>{{props.item.transportationUserMoneyAccount.externalId}}</small>
                <div v-if="props.item.moneyAccountType">
                  <small>{{props.item.moneyAccountType.typeLabel}}</small>
                </div>
              </div>
              <div v-if="!props.item.transportationUserMomoAccount && !props.item.transportationUserMoneyAccount">
                Other (external)
              </div>
            </td>
            <td :title="props.item.notified ? 'Successfully sent SMS or email' : 'No message sent, click button to send'">
              <v-icon v-if="props.item.notified" small>fa fa-check-circle</v-icon>
              <v-icon v-if="!props.item.notified" small>fa fa-circle</v-icon>
              <v-btn small :loading="props.item.apiInProgress" :disabled="props.item.apiInProgress || props.item.logStatus === 'timedout'" @click.stop="resendNotification(props.index)">
                <span>{{props.item.notified ? 'Re-send' : 'Send'}}</span>
              </v-btn>
            </td>
            <td>
              {{props.item.dateUpdatedLabel}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <router-view
      :force-root-view-refresh="forceRootViewRefresh"
      :signed-in-user="signedInUser"
      :agencies="agencies"
      :selected-account="selectedAccount">
    </router-view>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
