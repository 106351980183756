import _cloneDeep from 'lodash.clonedeep'
import _isNil from 'lodash.isnil'
import moment from 'moment-timezone'
import { C_ADD_SITE_ALERT } from '@/utilities/mutation-types'

import {
  formatCurrency,
  formatDate,
  formatTime
} from '@smarttransit/common'

import {
  generateKeywordsQueryForOr,
  addParamsToLocation
} from '@smarttransit/common-client'

import { initBreadcrumbs } from '@/utilities/helpers'

import {
  findTotalPromotions,
  findPromotions,
  archivePromotion,
  unArchivePromotion,
  getPromotionScripts
} from '@/services/promotions-service'

export default {
  name: 'promotions',
  props: {
    signedInUser: Object
  },
  data () {
    return {
      searchKeywords: null,
      apiInProgress: false,
      archivedInProgress: false,
      breadcrumbs: [],
      promotionScripts: [],
      selectedScript: null,
      selectedTab: 'promotions',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateUpdated'
      },
      archivedPagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateArchived'
      },
      headers: [],
      archivedHeaders: [],
      totalPromotions: 0,
      promotions: [],
      totalArchivedPromotions: 0,
      archivedPromotions: [],
      dateFrom: null,
      dateTo: null,
      dateFromMenu: null,
      dateToMenu: null
    }
  },
  watch: {
    $route () {
      this.breadcrumbs = initBreadcrumbs.bind(this)()
      if (this.$router.currentRoute.name === 'promotions') {
        this.loadFromQueryParams()
        this.searchPromotions()
      }
    },
    dateFrom (val) {
      this.lastParams = addParamsToLocation({ dateFrom: val }, this.$route, this.lastParams)
    },
    dateTo (val) {
      this.lastParams = addParamsToLocation({ dateTo: val }, this.$route, this.lastParams)
    },
    pagination (updatedPagination) {
      if (this.selectedTab === 'promotions') {
        this.lastParams = addParamsToLocation(updatedPagination, this.$route, this.lastParams)
      }
    },
    archivedPagination (updatedPagination) {
      if (this.selectedTab === 'archived-promotions') {
        this.lastParams = addParamsToLocation({ adescending: updatedPagination.descending, apage: updatedPagination.page, arowsPerPage: updatedPagination.rowsPerPage, asortBy: updatedPagination.sortBy }, this.$route, this.lastParams)
      }
    }
  },
  mounted: function () {
    async function onRouterLoad () {
      this.headers = [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'customName'
        },
        {
          text: 'Script',
          align: 'left',
          sortable: true,
          value: 'fileName'
        },
        {
          text: 'Budget',
          align: 'left',
          sortable: true,
          value: 'budget'
        },
        {
          text: 'Spent',
          align: 'left',
          sortable: true,
          value: 'budgetSpent'
        },
        {
          text: 'Start',
          align: 'left',
          sortable: true,
          value: 'dateStart'
        },
        {
          text: 'End',
          align: 'left',
          sortable: true,
          value: 'dateEnd'
        },
        {
          text: 'Updated',
          align: 'left',
          sortable: true,
          value: 'dateUpdated'
        },
        { text: 'Actions', sortable: false }
      ]
      this.archivedHeaders = _cloneDeep(this.headers)
      this.archivedHeaders.splice(this.archivedHeaders.length - 2, 1, {
        text: 'Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      })
      this.archivedHeaders.splice(this.archivedHeaders.length - 1, 0, {
        text: 'Archived',
        align: 'left',
        sortable: true,
        value: 'dateArchived'
      })
      if (this.$router.currentRoute.name === 'promotions') {
        this.loadFromQueryParams()
        // load scripts
        await this.loadPromotionScripts()
        await this.searchPromotions()
      }
      this.routerLoaded = true
    }

    this.$nextTick(() => {
      this.breadcrumbs = initBreadcrumbs.bind(this)()
      onRouterLoad.bind(this)()
    })
  },
  computed: {
    computedDateFromFormatted () {
      return this.dateFrom ? formatDate(this.dateFrom) : ''
    },
    computedDateToFormatted () {
      return this.dateTo ? formatDate(this.dateTo) : ''
    },
    computedDateFromMax () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null
      if (toDateAsMoment && (!fromDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return toDateAsMoment.subtract(1, 'day').format('Y-MM-DD')
      }
      return ''
    },
    computedDateToMin () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null
      if (fromDateAsMoment && (!toDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return fromDateAsMoment.add(1, 'day').format('Y-MM-DD')
      }
      return ''
    }
  },
  methods: {
    loadFromQueryParams () {
      if (this.$route.query.descending) {
        this.pagination.descending = this.$route.query.descending
      }
      if (this.$route.query.page) {
        this.pagination.page = this.$route.query.page
      }
      if (this.$route.query.rowsPerPage) {
        this.pagination.rowsPerPage = this.$route.query.rowsPerPage
      }
      if (this.$route.query.sortBy) {
        this.pagination.sortBy = this.$route.query.sortBy
      }
      if (this.$route.query.adescending) {
        this.archivedPagination.descending = this.$route.query.adescending
      }
      if (this.$route.query.apage) {
        this.archivedPagination.page = this.$route.query.apage
      }
      if (this.$route.query.arowsPerPage) {
        this.archivedPagination.rowsPerPage = this.$route.query.arowsPerPage
      }
      if (this.$route.query.asortBy) {
        this.archivedPagination.sortBy = this.$route.query.asortBy
      }
      if (this.$route.query.searchKeywords) {
        this.searchKeywords = this.$route.query.searchKeywords
      }
      if (this.$route.query.dateFrom) {
        this.dateFrom = this.$route.query.dateFrom
      }
      if (this.$route.query.dateTo) {
        this.dateTo = this.$route.query.dateTo
      }
      if (this.$route.query.selectedTab) {
        this.selectedTab = this.$route.query.selectedTab
      }
    },
    async loadPromotionScripts () {
      try {
        this.apiInProgress = true
        const scripts = await getPromotionScripts()
        this.promotionScripts = scripts.map(o => ({ value: o.value, text: o.value, meta: { description: o.description } }))
        this.apiInProgress = false
      } catch (_) {
        this.apiInProgress = false
      }
    },
    async searchPromotions (category) {
      try {
        this.apiInProgress = true
        category = category || this.selectedTab
        if (category === 'archived-promotions') {
          this.getTotalPromotions().then((total) => {
            this.totalPromotions = total.count
          })
          const [retrievedArchivedPromotions, total] = await Promise.all([
            this.getArchivedPromotions(this.searchKeywords),
            this.getTotalArchivedPromotions(this.searchKeywords)
          ])
          this.totalArchivedPromotions = total.count
          this.archivedPromotions = this.parsePromotions(retrievedArchivedPromotions)
        } else {
          this.getTotalArchivedPromotions().then((result) => {
            this.totalArchivedPromotions = result.count
          })
          const [retrievedPromotions, total] = await Promise.all([
            this.getPromotions(this.searchKeywords),
            this.getTotalPromotions(this.searchKeywords)
          ])
          this.totalPromotions = total.count
          this.promotions = this.parsePromotions(retrievedPromotions)
        }

        this.apiInProgress = false
      } catch (_) {
        this.apiInProgress = false
      }
    },
    async loadPromotion (promotion) {
      this.$router.push({ name: 'promotions-promotion', params: { promotionId: promotion.id } })
    },
    parsePromotions (promotions) {
      if (!promotions) {
        return []
      }
      return promotions.map((o) => {
        o.expiredTooltip = []
        if (o.dateStart && o.dateEnd) {
          o.isExpired = Date.now() < new Date(o.dateStart).getTime() && Date.now() >= new Date(o.dateEnd).getTime()
        } else if (!o.dateStart && o.dateEnd) {
          o.isExpired = Date.now() >= new Date(o.dateEnd).getTime()
        } else if (o.dateStart && !o.dateEnd) {
          o.isExpired = Date.now() < new Date(o.dateStart).getTime()
        }
        if (o.isExpired) {
          o.expiredTooltip.push('Start date and/or end date is out of range')
        }
        if (o.budgetSpent >= o.budget) {
          o.expiredTooltip.push('Budget has been spent')
        }
        if (o.expiredTooltip.length) {
          o.expiredTooltip = 'Reasons: ' + o.expiredTooltip.join(', ')
        } else {
          o.expiredTooltip = ''
        }
        if (o.budgetThreshold) {
          o.thresholdWarning = o.budgetSpent >= o.budgetThreshold
        }
        o.dateStartLabel = o.dateStart ? `${formatDate(o.dateStart)} ${formatTime(o.dateStart)}` : ''
        o.dateEndLabel = o.dateEnd ? `${formatDate(o.dateEnd)} ${formatTime(o.dateEnd)}` : ''
        o.dateCreatedLabel = `${formatDate(o.dateCreated)} ${formatTime(o.dateCreated)}`
        o.dateArchivedLabel = this.selectedTab === 'archived-promotions' ? `${formatDate(o.dateArchived)} ${formatTime(o.dateArchived)}` : null
        o.dateCreatedLabel = `${formatDate(o.dateCreated)} ${formatTime(o.dateCreated)}`
        o.dateUpdatedLabel = o.dateUpdated ? `${formatDate(o.dateUpdated)} ${formatTime(o.dateUpdated)}` : ''
        o.budgetLabel = _isNil(o.budget) ? 'n/a' : formatCurrency(o.budget)
        o.budgetSpentLabel = _isNil(o.budgetSpent) ? 'n/a' : formatCurrency(o.budgetSpent)
        o.budgetThresholdLabel = o.budgetThreshold ? formatCurrency(o.budgetThreshold) : ''
        o.isDraft = o.metadata === null
        return o
      })
    },
    generateSearchQuery (searchKeywords) {
      const { sortBy, descending, page, rowsPerPage } = this.selectedTab === 'archived-promotions' ? this.archivedPagination : this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
      let filter = {
        limit: rowsPerPage,
        offset,
        where: { dateArchived: null }
      }
      if (sortBy) {
        filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
      }
      if (searchKeywords) {
        filter.where.or = generateKeywordsQueryForOr(searchKeywords, ['customName', 'fileName', 'budget', 'budgetSpent', 'overBudgetSpent'])
      }
      if (this.selectedScript) {
        filter.where.fileName = this.selectedScript.value
      }
      if (this.dateFrom && this.dateTo) {
        filter.where.dateStart = { lte: this.dateFrom }
        filter.where.dateEnd = { gte: this.dateTo }
      } else if (!this.dateFrom && this.dateTo) {
        filter.where.dateEnd = { gte: this.dateTo }
      } else if (this.dateFrom && !this.dateTo) {
        filter.where.dateStart = { lte: this.dateFrom }
      }
      return filter
    },
    async getTotalPromotions (searchKeywords) {
      try {
        const filter = this.generateSearchQuery(searchKeywords)
        return findTotalPromotions({ where: filter.where })
      } catch (err) {}
    },
    async getPromotions (searchKeywords) {
      try {
        const filter = this.generateSearchQuery(searchKeywords)
        return findPromotions(filter)
      } catch (err) {}
    },
    async getTotalArchivedPromotions (searchKeywords) {
      try {
        const filter = this.generateSearchQuery(searchKeywords)
        filter.where.dateArchived = { neq: null }
        return findTotalPromotions({ where: filter.where })
      } catch (err) {}
    },
    async getArchivedPromotions (searchKeywords) {
      try {
        const filter = this.generateSearchQuery(searchKeywords)
        filter.where.dateArchived = { neq: null }
        return findPromotions(filter)
      } catch (err) {}
    },
    async archivePromotion (promotion) {
      if (confirm('Confirm archiving promotion: "' + promotion.customName + '"')) {
        try {
          promotion.isArchiveInProgress = true
          await archivePromotion({ id: promotion.id })
          promotion.isArchiveInProgress = false
          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Archived promotion: ${promotion.customName}`,
            type: 'success',
            transient: true
          })
          this.searchPromotions()
        } catch (_) {
          promotion.isArchiveInProgress = false
        }
      }
    },
    async deletePromotion (promotion) {
      if (promotion.metadata && Object.keys(promotion.metadata).length && promotion.budgetSpent > 0) {
        return alert('Cannot permanently delete promotions that were published and the budget used')
      }
      if (confirm('Confirm PERMANENTLY deleting promotion: "' + promotion.customName + '"')) {
        try {
          promotion.isArchiveInProgress = true
          await archivePromotion({ id: promotion.id, permanently: true })
          promotion.isArchiveInProgress = false
          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `PERMANENTLY deleted promotion: ${promotion.customName}`,
            type: 'success',
            transient: true
          })
          this.selectedTab = 'promotions'
          this.searchPromotions()
        } catch (_) {
          promotion.isArchiveInProgress = false
        }
      }
    },
    async unArchivePromotion (promotion) {
      if (confirm('Confirm un-archiving promotion: "' + promotion.customName + '"')) {
        try {
          promotion.isUnArchiveInProgress = true
          await unArchivePromotion({ id: promotion.id })
          promotion.isUnArchiveInProgress = false
          this.selectedTab = 'promotions'
          this.searchPromotions()
        } catch (_) {
          promotion.isUnArchiveInProgress = false
        }
      }
    },
    onKeywordsSearch () {
      this.lastParams = addParamsToLocation({ searchKeywords: this.searchKeywords || null }, this.$route, this.lastParams)
      this.searchPromotions()
    },
    onTabChanged (val) {
      if (this.pagination || this.archivedPagination) {
        if (val === 'archived-promotions') {
          this.lastParams = addParamsToLocation({
            descending: null,
            page: null,
            rowsPerPage: null,
            sortBy: null,
            adescending: this.archivedPagination.descending,
            apage: this.archivedPagination.page,
            arowsPerPage: this.archivedPagination.rowsPerPage,
            asortBy: this.archivedPagination.sortBy
          }, this.$route, this.lastParams)
        } else {
          this.lastParams = addParamsToLocation({
            adescending: null,
            apage: null,
            arowsPerPage: null,
            asortBy: null,
            ...this.pagination
          }, this.$route, this.lastParams)
        }
        if (this.routerLoaded) {
          this.lastParams = addParamsToLocation({ selectedTab: val }, this.$route, this.lastParams)
          this.searchPromotions(val)
        }
      }
    },
    onPagination () {
      if (this.selectedTab !== 'archived-promotions' && this.routerLoaded) {
        this.searchPromotions()
      }
    },
    onArchivedPagination () {
      if (this.selectedTab === 'archived-promotions' && this.routerLoaded) {
        this.searchPromotions()
      }
    }
  }
}
