import moment from 'moment-timezone'
import _orderBy from 'lodash.orderby'
import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'
import { C_ADD_SITE_ALERT } from '../../../../utilities/mutation-types'
import { formatDate, formatTime } from '@smarttransit/common'

import {
  createScheduledPayout,
  updateScheduledPayout,
  getScheduledPayout,
  deleteScheduledPayout,
  getTransportationOwnerMomoAndMoneyAccounts
} from '../../../../services/transportation-owner-users-service'

import { addAlert, isAuthorized } from '@/utilities/helpers'
import { getCachedMoneyAccountTypes } from '@/services/st-settings-service'

const defaultFrequencyDate = moment().month(0).day(6).hour(22).minute(0)
const defaultBiWeeklyDays = []
for (let i = 1; i < 15; i++) {
  defaultBiWeeklyDays.push(i)
}

const defaultMonthlyDates = []
for (let i = 1; i < 29; i++) {
  defaultMonthlyDates.push(i)
}
defaultMonthlyDates.unshift('lastofmonth')

const requiredDayOfTheWeek = 6 // saturday
let biWeeklyMoment = moment().startOf('month')
if (biWeeklyMoment.day() !== requiredDayOfTheWeek) {
  let numberOfDaysUntilRequiredDay
  if (requiredDayOfTheWeek > biWeeklyMoment.day()) {
    numberOfDaysUntilRequiredDay = requiredDayOfTheWeek - biWeeklyMoment.day()
    biWeeklyMoment.add(numberOfDaysUntilRequiredDay, 'days')
  } else {
    numberOfDaysUntilRequiredDay = biWeeklyMoment.day() - requiredDayOfTheWeek
    biWeeklyMoment.subtract(numberOfDaysUntilRequiredDay, 'days')
  }
}
const defaultBiWeeklyDate = biWeeklyMoment.date()

export default {
  name: 'transportation-accounts-payouts-schedule',
  props: {
    signedInUser: Object,
    selectedAccount: Object,
    agencies: Array
  },
  data () {
    return {
      localTimezone: moment.tz.guess(),
      originalScheduledPayoutToSubmit: null,
      apiInProgress: false,
      disableApiInProgress: false,
      deleteApiInProgress: false,
      saveApiInProgress: false,
      isCreatingScheduledPayout: false,
      scheduledJobStartDate: null,
      scheduledJobStartDateMinimum: moment().subtract(1, 'day').toDate().toISOString(),
      scheduledJobStartDateMenu: null,
      frequencies: [{ text: 'Daily', value: 'daily' }, { text: 'Weekly', value: 'weekly' }, { text: 'Twice a month', value: 'bi-weekly' }, { text: 'Monthly', value: 'monthly' }],
      weekdays: [1, 2, 3, 4, 5, 6, 0].map(o => {
        return { text: moment().day(o).format('dddd'), value: o }
      }),
      biWeeklyDates: defaultBiWeeklyDays.map(o => {
        return { text: moment().date(o).format('Do'), value: o }
      }),
      monthlyDates: defaultMonthlyDates.map(o => {
        if (o === 'lastofmonth') {
          return { text: 'Last day of the month', value: o }
        }
        return { text: moment().date(o).format('Do'), value: o }
      }),
      timeLabel: '',
      timeValue: null,
      biWeeklyHint: '',
      secondBiWeeklyDate: null,
      scheduledPayoutToSubmit: {
        timezoneSelected: null,
        frequency: null,
        biWeeklyDate: null,
        monthlyDate: null,
        weekday: null,
        time: null,
        agencyId: 'GPRTU',
        moneyAccountId: null
      },
      scheduledPayouts: [],
      transportationOwnerUser: null,
      recentPayoutActivityHtml: '',
      scheduledPayoutHtml: '',
      transportationMoneyAccounts: []
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      this.apiInProgress = true
      try {
        const moneyAccountTypes = await getCachedMoneyAccountTypes()

        let validTransportationOwnerUserIds = null

        if (!isAuthorized(this.$props.signedInUser, 'admin')) {
          validTransportationOwnerUserIds = this.$props.signedInUser.transportationOwnerUsers.filter((o) => (o.transportationOwnerId === this.$props.selectedAccount.id)).map((o) => o.id)
        }

        const { transportationMoneyAccounts, transportationMomoAccounts } = await getTransportationOwnerMomoAndMoneyAccounts({ transportationOwnerId: this.$route.params.accountId, transportationOwnerUserIds: validTransportationOwnerUserIds })

        const moneyAccountLogos = this.$store.getters.getMoneyAccountLogos

        if (!transportationMomoAccounts.length && !transportationMoneyAccounts.length) {
          throw new Error('The current transportation account\'s owner was not found or has no money account types registered')
        }

        this.transportationMoneyAccounts = transportationMomoAccounts.map((o) => {
          return {
            text: o.transportationUserIdentifier.phoneNumber,
            metadata: {
              src: moneyAccountLogos ? moneyAccountLogos[o.moneyAccountTypeId] : null,
              moneyAccountTypeId: o.moneyAccountTypeId,
              moneyAccountTypeCategory: moneyAccountTypes.find((m) => m.value === o.moneyAccountTypeId)?.typeCategory
            },
            value: o.id
          }
        })

        this.transportationMoneyAccounts = this.transportationMoneyAccounts.concat(transportationMoneyAccounts.map((o) => {
          return {
            text: o.externalId,
            metadata: {
              icon: this.$store.getters.getMoneyAccountTypes.find((m) => m.value === o.moneyAccountTypeId).metadata.icon,
              moneyAccountTypeId: o.moneyAccountTypeId,
              accountMetadata: o.accountMetadata,
              moneyAccountTypeCategory: moneyAccountTypes.find((m) => m.value === o.moneyAccountTypeId)?.typeCategory
            },
            value: o.id
          }
        }))

        if (this.transportationMoneyAccounts.length === 1) {
          this.scheduledPayoutToSubmit.moneyAccountId = this.transportationMoneyAccounts[0].value
        }

        // get the scheduled payout(s)
        await this.getScheduledPayouts()
      } catch (err) {
        console.log(err)
        addAlert({ message: err, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    })

    this.unsubscribeScheduledPayoutToSubmit = this.$watch('scheduledPayoutToSubmit', () => {
      this.scheduledJobStartDateMinimum = this.timezonedMoment().subtract(1, 'day').toDate().toISOString()
      this.generateScheduledPayoutHtml()
    }, { deep: true })

    this.unsubscribeBiWeeklyDate = this.$watch('scheduledPayoutToSubmit.biWeeklyDate', (newVal) => {
      if (newVal) {
        this.biWeeklyHint = ''

        if (newVal) {
          this.secondBiWeeklyDate = newVal + 14
          this.biWeeklyHint = `Second payout will be on the ${moment().date(this.secondBiWeeklyDate).format('Do')}`
        }

        this.generateScheduledPayoutHtml()
      }
    })
  },
  watch: {
    timeValue (val) {
      this.scheduledPayoutToSubmit.time = val
      this.timeLabel = moment.utc(val).format('hh:mma')
    }
  },
  computed: {
    computedScheduledJobStartFormatted () {
      return this.scheduledJobStartDate ? formatDate(this.scheduledJobStartDate) : ''
    },
    currentSelectedAccount () {
      return this.$props.selectedAccount
    },
    currentAgencies () {
      return this.$props.agencies
    },
    currentMomoAccountLabel () {
      if (this.transportationMoneyAccounts.length && this.scheduledPayoutToSubmit.moneyAccountId) {
        return this.transportationMoneyAccounts.find(o => o.value === this.scheduledPayoutToSubmit.moneyAccountId).text
      }

      return ''
    }
  },
  methods: {
    resetTimezone () {
      if (confirm('Confirm setting the timezone to your local (browser) timezone')) {
        this.scheduledPayoutToSubmit.timezoneSelected = this.localTimezone
      }
    },
    timezonedMoment (constructorParam) {
      return moment(constructorParam).tz(this.scheduledPayoutToSubmit.timezoneSelected || this.localTimezone)
    },
    async enableDisableSchedule (type) {
      try {
        if (confirm(type === 'enable' ? 'Confirm enabling this job to run on the next due date' : 'Confirm disabling this job and stopping it from running')) {
          this.disableApiInProgress = true

          this.scheduledPayouts = await updateScheduledPayout({
            transportationOwnerUserId: this.transportationOwnerUser.id,
            archived: type !== 'enable'
          })

          this.originalScheduledPayoutToSubmit = null

          if (this.scheduledPayouts && this.scheduledPayouts.length) {
            this.parseProperties()
            this.originalScheduledPayoutToSubmit = _cloneDeep(this.scheduledPayoutToSubmit)
          }

          this.disableApiInProgress = false

          this.$store.commit(C_ADD_SITE_ALERT, {
            message: 'Scheduled payout successfully ' + (type === 'enable' ? 'enabled' : 'disabled'),
            type: 'success',
            transient: true
          })
        }
      } catch (err) {
        this.disableApiInProgress = false

        this.$store.commit(C_ADD_SITE_ALERT, {
          message: `${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    },
    async deleteScheduledPayout () {
      try {
        if (confirm('Confirm deleting this scheduled payout PERMANENTLY')) {
          this.deleteApiInProgress = true

          await deleteScheduledPayout({
            transportationOwnerUserId: this.transportationOwnerUser.id
          })

          // this.originalScheduledPayoutToSubmit = null
          this.scheduledPayouts = []
          this.resetProperties()
          this.originalScheduledPayoutToSubmit = _cloneDeep(this.scheduledPayoutToSubmit)
          this.deleteApiInProgress = false

          this.$store.commit(C_ADD_SITE_ALERT, {
            message: 'Scheduled payout successfully deleted',
            type: 'success',
            transient: true
          })
        }
      } catch (err) {
        this.deleteApiInProgress = false

        this.$store.commit(C_ADD_SITE_ALERT, {
          message: `${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    },
    isAnyApiInProgress () {
      return this.apiInProgress || this.saveApiInProgress || this.disableApiInProgress || this.saveApiInProgress
    },
    async refresh () {
      if (!this.isFormDirty() || confirm('You have made updates to the scheduled payout. If you confirm the refresh, you will lose any changes.')) {
        return this.getScheduledPayouts()
      }
    },
    async saveScheduledPayout () {
      try {
        if ((this.scheduledPayoutToSubmit.frequency === 'bi-weekly' && !this.scheduledPayoutToSubmit.biWeeklyDate) ||
          (this.scheduledPayoutToSubmit.frequency === 'monthly' && !this.scheduledPayoutToSubmit.monthlyDate)) {
          return alert('Please select a payout date')
        } else if (this.scheduledPayoutToSubmit.frequency === 'weekly' && !this.scheduledPayoutToSubmit.weekday) {
          return alert('Please select a day of the week')
        }

        const frequencyDate = this.generateFrequencyDate()
        this.saveApiInProgress = true

        if (this.isCreatingScheduledPayout) {
          let momentScheduledJobStartDate

          if (this.scheduledJobStartDate) {
            momentScheduledJobStartDate = this.timezonedMoment(this.scheduledJobStartDate)
            const momentTimeObj = this.timezonedMoment(this.scheduledPayoutToSubmit.time)
            momentScheduledJobStartDate.hour(momentTimeObj.hour())
            momentScheduledJobStartDate.minute(momentTimeObj.minute())
          }

          this.scheduledPayouts = await createScheduledPayout({
            transportationOwnerUserId: this.transportationOwnerUser.id,
            scheduledDate: momentScheduledJobStartDate ? momentScheduledJobStartDate.valueOf() : null,
            frequency: this.scheduledPayoutToSubmit.frequency,
            frequencyDate: frequencyDate.toDate().toISOString(),
            transportationUserMomoAccountId: this.scheduledPayoutToSubmit.moneyAccountId,
            agencyId: this.scheduledPayoutToSubmit.agencyId,
            lastDayOfMonth: this.scheduledPayoutToSubmit.frequency === 'month' && this.scheduledPayoutToSubmit.monthlyDate === 'lastofmonth',
            timezone: this.scheduledPayoutToSubmit.timezoneSelected
          })

          this.isCreatingScheduledPayout = false

          this.$store.commit(C_ADD_SITE_ALERT, {
            message: 'Scheduled payout successfully created',
            type: 'success',
            transient: true
          })
        } else {
          this.scheduledPayouts = await updateScheduledPayout({
            transportationOwnerUserId: this.transportationOwnerUser.id,
            frequency: this.scheduledPayoutToSubmit.frequency,
            frequencyDate: frequencyDate.toDate().toISOString(),
            transportationUserMomoAccountId: this.scheduledPayoutToSubmit.moneyAccountId,
            agencyId: this.scheduledPayoutToSubmit.agencyId,
            lastDayOfMonth: this.scheduledPayoutToSubmit.frequency === 'month' && this.scheduledPayoutToSubmit.monthlyDate === 'lastofmonth',
            timezone: this.scheduledPayoutToSubmit.timezoneSelected
          })

          this.$store.commit(C_ADD_SITE_ALERT, {
            message: 'Scheduled payout successfully updated',
            type: 'success',
            transient: true
          })
        }
        this.originalScheduledPayoutToSubmit = null
        this.parseProperties()
        this.originalScheduledPayoutToSubmit = _cloneDeep(this.scheduledPayoutToSubmit)
        this.saveApiInProgress = false
      } catch (err) {
        this.saveApiInProgress = false
        this.$store.commit(C_ADD_SITE_ALERT, {
          message: `${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    },
    generateFrequencyDate () {
      const frequencyDate = this.timezonedMoment(this.scheduledPayoutToSubmit.time)
      if (this.scheduledPayoutToSubmit.weekday) {
        frequencyDate.day(this.scheduledPayoutToSubmit.weekday)
      }
      if (this.scheduledPayoutToSubmit.biWeeklyDate) {
        frequencyDate.date(this.scheduledPayoutToSubmit.biWeeklyDate)
      }
      if (this.scheduledPayoutToSubmit.monthlyDate && this.scheduledPayoutToSubmit.monthlyDate === 'lastofmonth') {
        frequencyDate.date(this.scheduledPayoutToSubmit.monthlyDate)
      }
      return frequencyDate
    },
    isFormDirty () {
      return !_isEqual(this.scheduledPayoutToSubmit, this.originalScheduledPayoutToSubmit)
    },
    cancel () {
      this.$router.push({ name: 'transportation-accounts-payouts' })
    },
    async getScheduledPayouts () {
      try {
        this.apiInProgress = true
        this.scheduledPayouts = this.transportationOwnerUser?.id ? await getScheduledPayout({ transportationOwnerUserId: this.transportationOwnerUser.id }) : []
        this.originalScheduledPayoutToSubmit = null

        if (this.scheduledPayouts?.length) {
          this.parseProperties()
        } else {
          this.resetProperties()
        }

        this.$nextTick(() => {
          this.originalScheduledPayoutToSubmit = _cloneDeep(this.scheduledPayoutToSubmit)
        })
      } catch (err) {
        console.log(err)
        addAlert({ message: err, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    },
    resetProperties () {
      this.isCreatingScheduledPayout = true
      this.scheduledPayoutToSubmit.frequency = 'weekly'
      this.scheduledPayoutToSubmit.monthlyDate = 'lastofmonth'
      this.scheduledPayoutToSubmit.biWeeklyDate = defaultBiWeeklyDate
      this.secondBiWeeklyDate = defaultBiWeeklyDate + 14
      this.scheduledPayoutToSubmit.weekday = defaultFrequencyDate.day()
      this.scheduledPayoutToSubmit.time = defaultFrequencyDate.toDate()
      this.timeValue = this.scheduledPayoutToSubmit.time
      this.scheduledPayoutToSubmit.timezoneSelected = this.localTimezone
      this.timeLabel = this.timezonedMoment(this.scheduledPayoutToSubmit.time).format('hh:mma')
      this.biWeeklyHint = ''

      if (this.secondBiWeeklyDate) {
        this.biWeeklyHint = `Second payout will be on the ${moment().date(this.secondBiWeeklyDate).format('Do')}`
      }

      if (this.transportationMoneyAccounts.length === 1) {
        this.scheduledPayoutToSubmit.moneyAccountId = this.transportationMoneyAccounts[0].value
      }
    },
    parseProperties () {
      const scheduledPayout = this.scheduledPayouts[0]
      this.scheduledPayoutToSubmit.timezoneSelected = scheduledPayout.metadata.timezone
      const { minute, hour, dayOfTheWeek, dayOfTheMonth } = scheduledPayout.jobCronExpanded
      let momentDate = moment.utc()
      momentDate.hour(parseInt(hour[0], 10)).minute(parseInt(minute[0], 10))

      if (dayOfTheWeek[0] !== '*') {
        momentDate.day(parseInt(dayOfTheWeek[0], 10))
      }

      if (this.scheduledPayouts.length === 1 && dayOfTheMonth[0] !== '*') {
        let extractedDayOfTheMonth = dayOfTheMonth[0]
        if (extractedDayOfTheMonth.indexOf(',') > -1) {
          extractedDayOfTheMonth = extractedDayOfTheMonth.split(',')[0]
        }
        momentDate.date(parseInt(extractedDayOfTheMonth, 10))
      }

      momentDate = this.timezonedMoment(momentDate.valueOf()) // we now convert the UTC moment obj to a local timezone moment obj

      this.scheduledPayoutToSubmit.time = momentDate.toDate()
      this.timeValue = this.scheduledPayoutToSubmit.time
      // this.timeLabel = momentDate.format('hh:mma')
      this.scheduledPayoutToSubmit.frequency = scheduledPayout.metadata.frequency

      switch (this.scheduledPayoutToSubmit.frequency) {
        case 'weekly':
          this.scheduledPayoutToSubmit.weekday = momentDate.day()
          break
        case 'bi-weekly':
          this.scheduledPayoutToSubmit.biWeeklyDate = momentDate.date()
          break
        case 'monthly':
          if (this.scheduledPayouts.length > 1) {
            this.scheduledPayoutToSubmit.monthlyDate = 'lastofmonth'
          } else {
            this.scheduledPayoutToSubmit.monthlyDate = momentDate.date()
          }
          break
      }

      this.scheduledPayoutToSubmit.moneyAccountId = scheduledPayout.metadata.transportationUserMomoAccountId
      this.scheduledPayoutToSubmit.agencyId = scheduledPayout.metadata.agencyId
      this.generatePayoutActivityHtml()
      this.generateScheduledPayoutHtml()
    },
    generatePayoutActivityHtml () {
      this.recentPayoutActivityHtml = ''
      const timezoneLabel = this.scheduledPayoutToSubmit.timezoneSelected ? ' (' + this.scheduledPayoutToSubmit.timezoneSelected + ')' : ''
      const dateLastRun = _orderBy(this.scheduledPayouts, (o) => o.dateLastRun ? this.timezonedMoment(o.dateLastRun).valueOf() : 0, ['desc'])[0].dateLastRun
      if (dateLastRun) {
        const lastRunLocalTime = this.scheduledPayoutToSubmit.timezoneSelected && this.scheduledPayoutToSubmit.timezoneSelected !== this.localTimezone ? `<div><small><em>Local time: ${formatTime(dateLastRun)} ${formatDate(dateLastRun)} (${this.localTimezone})</em></small></div>` : ''
        this.recentPayoutActivityHtml = `<li>Payout job last ran at ${formatTime(dateLastRun, this.scheduledPayoutToSubmit.timezoneSelected)} ${formatDate(dateLastRun, this.scheduledPayoutToSubmit.timezoneSelected)}${timezoneLabel}${lastRunLocalTime}</li>`
      }
      if (!this.scheduledPayouts[0].dateArchived) {
        if (this.scheduledPayouts.filter(o => o.dateNextRun).length) {
          const dateNextRun = _orderBy(this.scheduledPayouts.filter(o => o.dateNextRun), (o) => this.timezonedMoment(o.dateNextRun).valueOf(), ['asc'])[0].dateNextRun
          const nextRunLocalTime = this.scheduledPayoutToSubmit.timezoneSelected && this.scheduledPayoutToSubmit.timezoneSelected !== this.localTimezone ? `<div><small><em>Local time: ${formatTime(dateNextRun)} ${formatDate(dateNextRun)} (${this.localTimezone})</em></small></div>` : ''
          this.recentPayoutActivityHtml += `<li>Next payout job will run at ${formatTime(dateNextRun, this.scheduledPayoutToSubmit.timezoneSelected)} ${formatDate(dateNextRun, this.scheduledPayoutToSubmit.timezoneSelected)}${timezoneLabel}${nextRunLocalTime}</li>`
        }
        if (this.recentPayoutActivityHtml) {
          this.recentPayoutActivityHtml = `<ul>${this.recentPayoutActivityHtml}</ul>`
        }
      }
    },
    generateScheduledPayoutHtml () {
      this.scheduledPayoutHtml = ''
      const timezoneLabel = this.scheduledPayoutToSubmit.timezoneSelected ? ' (' + this.scheduledPayoutToSubmit.timezoneSelected + ')' : ''
      if (this.scheduledPayoutToSubmit.frequency && this.timeLabel) {
        switch (this.scheduledPayoutToSubmit.frequency) {
          case 'daily':
            this.scheduledPayoutHtml = `
This scheduled payout is daily at ${this.timeLabel}${timezoneLabel}
`
            break
          case 'weekly':
            if (this.scheduledPayoutToSubmit.weekday || this.scheduledPayoutToSubmit.weekday === 0) {
              this.scheduledPayoutHtml = `
This scheduled payout is weekly on ${this.timezonedMoment().day(this.scheduledPayoutToSubmit.weekday).format('dddd')} at ${this.timeLabel}${timezoneLabel}
`
            }
            break
          case 'bi-weekly':
            if (this.scheduledPayoutToSubmit.biWeeklyDate) {
              const secondBiWeeklyDate = this.secondBiWeeklyDate ? this.timezonedMoment().date(this.secondBiWeeklyDate).format('Do') : ''
              this.scheduledPayoutHtml = `
This scheduled payout is twice a month on the ${this.timezonedMoment().date(this.scheduledPayoutToSubmit.biWeeklyDate).format('Do')} and ${secondBiWeeklyDate} at ${this.timeLabel}${timezoneLabel}
`
            }
            break
          case 'monthly':
            if (this.scheduledPayoutToSubmit.monthlyDate || this.scheduledPayoutToSubmit.monthlyDate === 0) {
              this.scheduledPayoutHtml = `
This scheduled payout is once a month on the ${this.scheduledPayoutToSubmit.monthlyDate === 'lastofmonth' ? 'last day of every month' : this.timezonedMoment().month(0).date(this.scheduledPayoutToSubmit.monthlyDate).format('Do')} at ${this.timeLabel}${timezoneLabel}
`
            }
            break
        }

        if (this.isCreatingScheduledPayout) {
          this.scheduledPayoutHtml = `<p>${this.scheduledPayoutHtml}</p>`
          if (this.scheduledJobStartDate) {
            this.scheduledPayoutHtml += `The first payout job will run at ${formatTime(this.scheduledJobStartDate, this.scheduledPayoutToSubmit.timezoneSelected)} ${formatDate(this.scheduledJobStartDate, this.scheduledPayoutToSubmit.timezoneSelected)}${timezoneLabel}`
          }
        }
      }
    }
  },
  beforeDestroy () {
    if (this.unsubscribeScheduledPayoutToSubmit) {
      this.unsubscribeScheduledPayoutToSubmit()
    }
    if (this.unsubscribeBiWeeklyDate) {
      this.unsubscribeBiWeeklyDate()
    }
  }
}
