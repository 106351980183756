<template>
  <div class="transportation-accounts-payouts-payout">
    <div v-if="$router.currentRoute.name === 'transportation-accounts-payouts-payout'" class="mx-2">
        <v-layout row wrap align-center v-if="$route.params.payoutId === '0'">
          <v-flex sm3 pa-2>
            <v-select
              v-model="moneyAccountId"
              :items="transportationMoneyAccounts"
              label="Money account"
            >
              <template slot="item" slot-scope="{ item }">
                <div class="layout row justify-start align-center">
                  <div>
                    <div>{{item.text}}</div>
                    <div v-if="item.metadata.accountMetadata && item.metadata.accountMetadata.bankName"><small>({{item.metadata.accountMetadata.bankName}})</small></div>
                  </div>
                  <div v-if="item.metadata.moneyAccountTypeId" class="ml-2">
                    <div v-if="item.metadata.src">
                      <img v-if="item.metadata.src" :src="item.metadata.src" style="height: 30px;" :alt="item.metadata.moneyAccountTypeId" />
                    </div>
                    <div v-if="item.metadata.icon">
                      <v-icon medium>{{item.metadata.icon}}</v-icon>
                    </div>
                  </div>
                </div>
              </template>
              <template slot="selection" slot-scope="{ item }">
                <div class="layout row justify-start align-center">
                  <div>
                    <div>{{item.text}}</div>
                    <div v-if="item.metadata.accountMetadata && item.metadata.accountMetadata.bankName"><small>({{item.metadata.accountMetadata.bankName}})</small></div>
                  </div>
                  <div v-if="item.metadata.moneyAccountTypeId" class="ml-2">
                    <div v-if="item.metadata.src">
                      <img v-if="item.metadata.src" :src="item.metadata.src" style="height: 30px;" :alt="item.metadata.moneyAccountTypeId" />
                    </div>
                    <div v-if="item.metadata.icon">
                      <v-icon medium>{{item.metadata.icon}}</v-icon>
                    </div>
                  </div>
                </div>
              </template>
            </v-select>
          </v-flex>
          <v-flex sm2 pa-2>
            <v-select
              v-model="agencyId"
              :items="currentAgencies"
              label="Agency"
            />
          </v-flex>
          <v-flex sm4 pa-2>
            <v-layout>
              <v-flex xs6 pa-2>
                <v-menu
                  v-model="dateFromMenu"
                  :close-on-content-click="false"
                  full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      prepend-icon="fal fa-calendar"
                      :value="computedDateFromFormatted"
                      @click:clear="dateFrom = null"
                      clearable
                      label="From"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    no-title
                    :max="computedDateFromMax"
                    v-model="dateFrom"
                    @change="dateFromMenu = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs6 pa-2>
                <v-menu
                  v-model="dateToMenu"
                  :close-on-content-click="false"
                  full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      prepend-icon="fal fa-calendar"
                      :value="computedDateToFormatted"
                      @click:clear="dateTo = null"
                      clearable
                      label="To"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    no-title
                    :min="computedDateToMin"
                    v-model="dateTo"
                    @change="dateToMenu = false"
                  />
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm2 pa-2>
            <v-btn :class="report && !currentPayout ? '' : 'primary'" :disabled="apiInProgress" @click="getPendingPayoutReport()">Generate a pending report</v-btn>
          </v-flex>
        </v-layout>
        <div v-if="$route.params.payoutId !== '0'" class="layout row justify-end">
          <v-btn class="primary" :disabled="apiInProgress" :to="{ name: 'transportation-accounts-payouts-payout', params: { payoutId: '0' } }">Prepare a pending report</v-btn>
        </div>
        <div class="layout column align-center justify-center mt-2 mb-5">
          <v-progress-circular v-if="apiInProgress && !report" indeterminate :size="50" color="primary" />
          <v-alert type="info" v-show="!reportError && !report && $route.params.payoutId === '0' && !dateFrom">
            Please select from the options above and generate a report
          </v-alert>
          <v-alert type="warning" v-show="reportError">
            {{reportError}}
          </v-alert>
          <div v-if="report">
            <div v-if="!currentPayout" class="mb-2">
              <h2 class="text-xs-center">Pending Payout Report</h2>
            </div>
            <div v-if="currentPayout" class="mb-2">
              <h2 class="text-xs-center">Payout Report</h2>
              <div class="text-xs-center">Transaction ID: <small>{{this.currentPayout.id}}</small></div>
            </div>
            <div v-if="report">
              <div v-for="(reportItem, key) in report" :key="key" class="elevation-1" style="width: 500px">
              <v-layout row align-center>
                <v-flex sm5 pa-2 class="text-xs-right">
                  <strong>Agency</strong>
                </v-flex>
                <v-flex sm7 pa-2>
                  {{reportItem.agencyLabel}}
                </v-flex>
              </v-layout>
              <v-layout row align-center v-if="currentPayout">
                <v-flex sm5 pa-2 class="text-xs-right">
                  <strong>Paid out to</strong>
                </v-flex>
                <v-flex sm7 pa-2>
                  <div v-if="currentPayout.transportationUserMomoAccount">
                    {{currentPayout.transportationUserMomoAccount.transportationUserIdentifier.phoneNumber}}
                    <v-chip small>{{currentPayout.transportationUserMomoAccount.moneyAccountTypeId}}</v-chip>
                  </div>
                  <div v-if="currentPayout.transportationUserMoneyAccount">
                    {{currentPayout.transportationUserMoneyAccount.externalId}}
                    <div v-if="currentPayout.logMetadata.notes"><small>{{currentPayout.logMetadata.notes}}</small></div>
                  </div>
                  <div v-if="!currentPayout.transportationUserMomoAccount && !currentPayout.transportationUserMoneyAccount">
                    Other (external)
                  </div>
                </v-flex>
              </v-layout>
              <v-layout row align-center>
                <v-flex sm5 pa-2 class="text-xs-right">
                  <strong>Gross amount</strong>
                </v-flex>
                <v-flex sm7 pa-2>
                  {{reportItem.grossPayoutLabel}}
                </v-flex>
              </v-layout>
              <v-layout row align-center>
                <v-flex sm5 pa-2 class="text-xs-right">
                  <strong>Fees</strong>
                </v-flex>
                <v-flex sm7 pa-2>
                  {{reportItem.feeDeductionLabel}}
                  <v-chip small>
                    {{reportItem.feeLabel}}
                  </v-chip>
                </v-flex>
              </v-layout>
              <v-layout row align-center>
                <v-flex sm5 pa-2 class="text-xs-right">
                  <strong>Amount</strong>
                </v-flex>
                <v-flex sm7 pa-2>
                  <v-chip :color="currentPayout && currentPayout.logStatus === 'completed' ? 'success' : 'default'" :text-color="currentPayout && currentPayout.logStatus === 'completed' ? '#fff' : ''">
                    <v-icon small>fal fa-envelope-open-dollar</v-icon> &nbsp;
                    {{reportItem.payoutLabel}}
                  </v-chip>
                </v-flex>
              </v-layout>
              <v-layout row align-center v-if="currentPayout && currentPayout.logStatus === 'completed'">
                <v-flex sm5 pa-2 class="text-xs-right">
                  <v-icon small color="success">fa fa-check-circle</v-icon> &nbsp; <strong class="text--success">Paid on</strong>
                </v-flex>
                <v-flex sm7 pa-2>
                  {{reportItem.dateUpdatedLabel}}
                </v-flex>
              </v-layout>
              <v-layout row align-center>
                <v-flex sm5 pa-2 class="text-xs-right">
                  <strong>Purchases Date / Date Range</strong>
                </v-flex>
                <v-flex sm7 pa-2>
                  {{reportItem.dateStartLabel}}<span v-if="reportItem.dateEndLabel"> to {{reportItem.dateEndLabel}}</span>
                </v-flex>
              </v-layout>
              <v-layout row align-center>
                <v-flex sm5 pa-2 class="text-xs-right">
                  <strong>Purchases for this Period</strong>
                </v-flex>
                <v-flex sm7 pa-2>
                  {{reportItem.total}}
                </v-flex>
              </v-layout>
              <v-layout row align-center v-if="reportItem.byStUserId">
                <v-flex sm5 pa-2 class="text-xs-right">
                  <strong>Marked as paid by</strong>
                </v-flex>
                <v-flex sm7 pa-2>
                  {{reportItem.byStUserLabel}}
                </v-flex>
              </v-layout>
              <hr class="report-segment" />
            </div>
            </div>
          </div>
          <div v-if="report && !currentPayout">
            <v-btn color="primary" :loading="apiInProgress" :disabled="apiInProgress || currentCategoryType !== 'mobile_money'" @click="requestPayout()">Make Payout to: {{currentMomoAccountLabel}}</v-btn>
            <v-btn v-if="isSuperAdmin" color="warning" :loading="markPaidApiInProgress" :disabled="apiInProgress || markPaidApiInProgress" @click="markAsPaid()">Mark As Paid</v-btn>
          </div>
        </div>
    </div>
  </div>
</template>

<style scoped>
  .report-segment:last-child {
    display: none
  }
</style>

<script>
import src from './src'
export default src
</script>
